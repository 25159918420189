.cookiesAlert {
  background: $bg-primary !important;
  font-weight: 400;
  display: flex;
  align-items: center !important;
  a {
    color: $text-success;
    text-decoration: underline;
  }
  button {
    border-radius: 2rem;
  }
}
