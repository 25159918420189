.alertMessage {
  @extend .wrapContainer;
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    @extend .innerContainer;
    text-align: center;
    .alert {
      display: inline-flex;
      align-items: center;
      margin: 0 auto;
      border: $border;
      border-radius: $radius;
      text-align: left;
      .icon {
        position: relative;
        width: 50px;
        height: 50px;
        img {
          width: 32px;
          height: 32px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .text {
        padding-right: 1rem;
        p {
          margin-bottom: 0;
        }
      }
      &.success {
        color: $text-success;
        a {
           text-decoration: underline; 
           color: $text-success;
        }
      }
      &.error {
        color: $text-error;
        a {
          text-decoration: underline; 
          color: $text-error;
       }
      }
    }
  }
}