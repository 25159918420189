.background {
    fill: none;
    pointer-events: all;
}

#states {
    fill: none;
    stroke: #fff;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

#states .active {
    display: none;
}

.state:hover {
    fill: #C5C5C5;
   cursor: pointer;
}
