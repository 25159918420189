.secondaryHeader {
  @extend .wrapContainer;
  height: 170px;
  overflow: hidden;
  color: #fff;
  position: relative;
  background: darken($bg-primary, $amount: 5%);
  img.banner {
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100;
    transform: translateX(-50%) translateY(-50%);
    margin-left: -1px;
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: transparentize($bg-primary, $amount: .9);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 200;
  }
  .pageTitle {
    @extend .innerContainer;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 300;
    h1 {
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
      font-size: 2rem;
      color: $text-green;
    }
  }
  @include media-max(992px) {
    height: 130px;
    img.banner {
      height: 100%;
    }
    .pageTitle h2 {
      font-family: $bodyFont;
      font-size: 1.5rem;
      text-align: center;
    }
  }
  @include media-max(767px) {
    margin-top: 1rem;
    height: 170px;
    .pageTitle {
      top: auto;
      left: 0;
      transform: none;
      bottom: 0;
      text-align: center;
      background: $bg-primary;
      color: #fff;
      padding: 0.5rem 0.25rem;
      h1 {
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }
  }
}
