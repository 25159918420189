.homeTemplate {
  @extend .wrapContainer;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  display: flex;
  flex-direction: column;
  .section_wrap {
    @extend .wrapContainer;
    display: flex;
    justify-content: center;
    .section {
      @extend .innerContainer;
      @include media-max(767px) {
        padding: 0;
      }
    }
  }

  // PROMOTIONAL BANNER
  .promotionalBanner {
    height: 210px;
    .section {
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      .banner_text {
        display: flex;
        flex-direction: column;
        color: $text-primary;
        h6 {
          margin-bottom: 0.25rem;
          font-size: 1.5rem;
          font-weight: 600;
          & + .text_wrap {
            position: relative;
            display: flex;
            flex-direction: column;
            p {
              margin-bottom: 0;
            }
            span {
              font-size: 1.25rem;
              font-weight: 500;
              & + span {
                font-size: 1.25rem;
                font-weight: 400;
                small {
                  font-size: 0.875rem;
                  display: block;
                  color: $text-error;
                  font-style: italic;
                  padding-top: 0.25rem;
                }
              }
            }
          }
        }
      }
      .code_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        span.code {
          text-align: center;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1;
          color: $text-primary;
          small {
            display: block;
            font-size: 0.75rem;
            font-size: 300;
          }
        }
        .pricing {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: $text-error;
            text-decoration: line-through;
            & + strong {
              font-size: 1.75rem;
              font-weight: 600;
              line-height: 1;
            }
          }
        }
      }
    }
    @include media-max(767px) {
      height: auto;
      margin-top: 1rem;
      .section {
        flex-direction: column;
        padding: 1rem;
        align-items: center;
        .banner_text {
          text-align: center;
        }
      }
    }
  }

  .hone_banner_02 {
    @extend .wrapContainer;
    position: relative;
    text-align: center;
    height: 550px;
    @include media-max(767px) {
      height: 400px;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .section_wrap {
      position: relative;
      z-index: 1000;
      height: 100%;
      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        span {
          font-size: 1.75rem;
          font-weight: 600;
          text-align: left;
          color: #1F353D;
          &.text-01 {
            font-size: 4rem;
            line-height: 1.2;
            margin-bottom: 1rem;
          }
          &.text-02 {
            line-height: 1.2;
            margin-bottom: 1.75rem;
            span {
              display: block;
            }
          }
          &.action {
            position: relative;
            a {
              display: inline-block;
              border: 1px solid $clr-primary;
              border-radius: $radius;
              background: #fff;
              color: $text-primary;
              padding: 0.75rem 1rem;
              font-weight: 600;
              text-decoration: none;
              font-size: 1.25rem;
              &:hover {
                background: $bg-primary;
                color: #fff;
              }
            }
          }
        }
        @include media-max(767px) {
          text-align: center;
          align-items: center;
          span {
            font-size: 1.25rem;
            text-align: center;
            &.text-01 {
              font-size: 2rem;
              line-height: 1.2;
              margin-bottom: 1rem;
            }
            &.text-02 {
              line-height: 1.2;
              margin-bottom: 1.75rem;
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  // DISCOUNTPROGRAMS
  .discountPrograms {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 2rem;
    // SECTIONTITLE
    .sectionTitle {
      position: relative;
      margin-bottom: 2rem;
      h1 {
        color: $text-primary;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: 600;
        & + span {
          font-size: 2rem;
          display: block;
          color: $text-primary;
          font-weight: 400;
          span {
            display: block;
          }
        }
      }
    }
    // MEMBERSHIPTYPE
    .membershipCard {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      align-self: center;
      .item {
        display: flex;
        justify-content: flex-start;
        column-gap: 2rem;
        align-items: center;
        margin-bottom: 1rem;
        .graphic {
          width: 350px;
          position: relative;
          > img {
            width: 100%;
            object-fit: cover;
          }
          & + .content {
            position: relative;
            h6 {
              margin-bottom: 1rem;
              color: $text-primary;
              font-weight: 600;
              font-size: 1.5rem;
              & + p {
                font-size: 1.25rem;
                margin-bottom: 1rem;
                & + a {
                  display: inline-block;
                  border-radius: $radius;
                  background: $bg-primary;
                  color: #fff;
                  padding: 0.75rem 1rem;
                  font-weight: 600;
                  text-decoration: none;
                  &:hover {
                    background: $bg-secondary;
                  }
                }
              }
            }
          }
        }
        @include media-max(767px) {
          display: flex;
          flex-direction: column;
        }
      }
    }
    // WHYUS
    .whyUs {
      position: relative;
      display: flex;
      padding: 1rem 0;
      margin-bottom: 2rem;
      ul {
        width: 100%;
        max-width: 950px;
        display: inline-block;
        list-style-type: none;
        margin: 0;
        li {
          padding: 0.25rem;
          font-size: 1.25rem;
          font-weight: 400;
          color: $text-primary;
          display: flex;
          column-gap: 1rem;
          a {
            width: 100px;
            border-radius: $radius;
            background: $bg-primary;
            color: #fff;
            padding: 0.25rem 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            font-weight: 600;
            &:hover {
              background: $bg-secondary;
            }
          }
        }
      }
    }
    @include media-max(767px) {
      padding-top: 1rem;
      .sectionTitle {
        margin-bottom: 1rem;
        padding: 0rem 1rem;
        h1 {
          font-size: 1.75rem;
          & + span {
            font-size: 1rem;
          }
        }
      }
      .membershipCard {
        display: flex;
        flex-direction: column;
        column-gap: 0;
        margin-bottom: 0;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: unset;
          margin-bottom: 0rem;
          .graphic {
            width: 100%;
          }
          .content {
            text-align: center;
            padding: 1rem;
          }
        }
      }
      .whyUs {
        background: #f1f1f1;
        margin-bottom: 0;
        ul {
          margin: 0;
          li {
            display: flex;
            flex-direction: column;
            font-size: 0.875rem;
            border-bottom: $border;
            padding-bottom: 1rem;
            text-align: center;
            &:last-of-type {
              border-bottom: none;
              padding-bottom: 0;
            }
            span {
              width: auto;
              background: none;
              color: $text-primary;
              font-weight: 500;
              padding: 0.25rem;
            }
          }
        }
      }
    }
  }

  // STATICES
  .staticesWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    overflow: hidden;
    position: relative;
    img.banner {
      width: 100%;
      height: 400px;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 100;
      transform: translateX(-50%) translateY(-50%);
    }
    .section {
      display: flex;
      justify-content: center;
      column-gap: 2rem;
      padding: 2rem 0;
      position: relative;
      z-index: 1000;
      .item {
        width: 300px;
        text-align: center;
        display: flex;
        flex-direction: column;
        color: #203342;
        position: relative;
        padding: 0 2rem;
        &:before {
          content: '';
          width: 2px;
          height: 100%;
          background: #203342;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:first-of-type:before {
          display: none;
        }
        span {
          font-size: 5rem;
          font-weight: 800;
          & + strong {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
    }
    @include media-max(767px) {
      height: auto;
      img.banner {
        height: 100%;
      }
      .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 0;
        padding: 0;
        .item {
          width: auto;
          border-bottom: 2px solid #203342;
          padding: 2rem;
          padding-top: 1.25rem;
          &:last-of-type {
            border-bottom: none;
          }
          &:before {
            display: none;
          }
          span {
            font-size: 3rem;
            font-weight: 800;
            & + strong {
              font-size: 1rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  // QUICKEXPLORE
  .quickExplore {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    .item {
      width: 300px;
      position: relative;
      .card {
        display: flex;
        flex-direction: column;
        height: calc(400px + 1rem);
        .graphic {
          position: relative;
          height: 200px;
          overflow: hidden;
          > img {
            object-fit: cover;
            width: 100%;
            transition: $transition;
          }
        }
        .cardInfo {
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
          padding: 1rem 2rem;
          span {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $text-primary;
            font-weight: 600;
            & + a {
              border: 1px solid $clr-primary;
              border-radius: $radius;
              padding: 0.75rem 1rem;
              font-weight: 600;
              color: $text-primary;
              text-decoration: none;
              font-size: 1rem;
              &:hover {
                background: $bg-primary;
                color: #fff;
              }
            }
          }
        }
        &:hover {
          position: relative;
          .graphic {
            position: relative;
            img {
              @include imgTransform;
            }
          }
        }
      }
    }
    @include media-max(767px) {
      flex-direction: column;
      padding: 0;
      .item {
        width: auto;
        position: relative;
        .card {
          display: flex;
          flex-direction: column;
          position: relative;
          height: auto;
          .graphic {
           height: auto;
           order: 0;
          }
          .cardInfo {
            height: auto;
            order: 1;
          }
        }
      }
    }
  }

  // CARDREGISTRATION
  .cardRegistration {
    background: #e7e7e7;
    position: relative;
    padding: 5rem 0;
    .advertisement {
      display: flex;
      column-gap: 3rem;
      justify-content: center;
      .graphic {
        width: 400px;
      }
      .advertisement_info {
        width: 500px;
        color: $text-primary;
        display: flex;
        flex-direction: column;
        h4 {
          margin-bottom: 1rem;
          font-weight: 400;
          span {
            display: block;
          }
          & + strong {
            display: block;
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 1.25rem;
          }
        }
        .handler {
          position: relative;
          display: block;
          a {
            display: inline-block;
            border-radius: $radius;
            padding: 0.75rem 1rem;
            font-weight: 600;
            background: $bg-primary;
            color: #fff;
            text-decoration: none;
            font-size: 1rem;
            &:hover {
              background: $bg-secondary;
            }
          }
        }
      }
    }
    @include media-max(767px) {
      padding: 0;
      .advertisement {
        flex-direction: column;
        column-gap: 0;
        .graphic {
          width: auto;
        }
        .advertisement_info {
          width: auto;
          padding: 1rem;
          padding-bottom: 1.75rem;
          text-align: center;
          h4 {
            font-weight: 500;
            font-size: 1.25rem;
            margin-bottom: 0.75rem;
            span {
              display: inline-block;
            }
            & + strong {
              margin-bottom: 0.75rem;
            }
          }
        }
      }
    }
  }

  // PROMOVIDEO
  .IntroductionVideo {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem !important;
    .introText {
      width: 100%;
      max-width: 950px;
      position: relative;
      padding: 1rem;
      font-size: 1rem;
      font-style: italic;
    }
    .videoContainer {
      position: relative;
      width: 100%;
      max-width: 950px;
      height: 550px;
      video {
        width: 100%;
        height: auto;
        border-radius: 1rem;
        border: $border;
      }
      @include media-max(767px) {
        height: auto;
        padding: 1rem;
        video {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  // COLLABORATIVE
  .collaborative {
    display: flex;
    flex-direction: column;
    .collaborative_title {
      background: #a6c0a7;
      text-align: center;
      padding: 1rem;
      text-align: center;
      color: #000004;
      font-size: 1.75rem;
      font-weight: 800;
    }
    .partnerships {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 2rem;
      padding: 2rem 10rem;
      margin-bottom: 3rem;
      @include media-max(767px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        padding: 2rem 6rem;
      }
    }
  }

  // EXPLOREFEATURES
  .exploreFeatures {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
    margin-bottom: 2rem;
    a {
      width: 250px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: $bg-primary;
      color: #d1deca;
      font-size: 1.25rem;
      text-decoration: none;
      font-weight: 600;
      span {
        display: block;
      }
      &:hover {
        color: #fff;
      }
    }
    @include media-max(767px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      a {
        display: block;
        width: auto;
        height: auto;
        border-bottom: $border;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 0.25rem 0;
        span {
          display: inline-block;
        }
      }
    }
  }

}

// carousel-root
.carousel-root {
  .carousel .slide {
    text-align: left !important;
    &:hover {
      cursor: text;
    }
  }
  .control-dots {
    text-align: right;
    padding: 0 2rem;
    .dot {
      background: #fff;
      width: 8px;
      height: 8px;
      opacity: 1;
      box-shadow: none;
      margin: 0 6px;
      &.selected {
        background: $bg-secondary;
      }
    }
  }
}
