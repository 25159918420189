#cannabis-education {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .introduction {
    padding-bottom: 1rem;
    a {
      color: red;
      text-decoration: underline;
    }
    h2 {
      color: $text-secondary;
      margin-bottom: 0.25rem;
      font-size: 1.25rem;
      & + p {
        margin-bottom: 0;
        strong {
          font-weight: 600;
          color: $text-primary;
        }
        & + .content {
          display: flex;
          flex-direction: column;
          margin-top: 0.75rem;
          p {
            margin-bottom: 0.5rem;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .topic_list {
    border-radius: $radius;
    border: $border;
    ul {
      list-style-type: none;
      margin: 0;
      li {
        border-bottom: $border;
        font-size: 1rem;
        font-weight: 500;
        color: $text-secondary;
        &:last-of-type {
          border-bottom: none;
        }
        &:nth-of-type(even) {
          background: #f6f6f6;
        }
        a {
          display: block;
          padding: 0.5rem 0.75rem;
          color: $text-secondary;
          text-decoration: none;
          &:hover {
            padding-left: 1rem;
          }
        }
      }
    }

  }


  .melissamashupVideo {
    display: grid;
    grid-template-columns: 350px auto;
    align-items: center;
    column-gap: 1rem;
    video {
      width: 100%!important;
      height: auto;
    }
    @include media-max(767px) {
      display: flex;
      flex-direction: column;

    }
  }
}


