.headerWrap {
  @extend .wrapContainer;
  padding: 1rem 0;
  .header {
    @extend .innerContainer;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    .logo {
      margin-right: auto;
      img {
        max-width: 300px;
      }
    }
    .sign-in, .contact-us {
      position: relative;
      a {
        display: inline-block;
        background: $bg-primary;
        padding: 0.5rem 0.75rem;
        color: #fff;
        border-radius: 0.5rem;
        text-decoration: none;
        i.fa {
          margin-right: 0.25rem;
        }
        &:hover {
          background: darken($bg-green-light, $amount: 10%);
          color: $text-green;
        }
      }
    }
    .navIcon {
      display: none;
      position: fixed;
      right: 1rem;
      top: 1rem;
      z-index: 3000;
      button {
        appearance: none;
        border: none;
        background: none;
        i.fa {
          font-size: 1.5rem;
          color: $text-secondary;
        }
      }
    }
  }
  @include media-max(767px) {
    padding: 0.25rem 0 0.5rem;
    .header {
      display: grid;
      grid-template-columns: max-content auto;
      justify-content: space-between;
      row-gap: 0.5rem;
      .logo img {
        max-width: 280px;
      }
      .search {
        display: none;
      }
      .sign-in {
        grid-column: 1 / 4;
        text-align: center;
        padding-bottom: 0.75rem;
        a {
          padding: 0.25rem 0.75rem;
          border-radius: 2rem;
          font-size: 0.75rem;
        }
      }
      .navIcon {
        display: block;
      }
    }
  }
}
