.enrollmentPlans {
  @extend .wrapContainer;
  position: relative;
  padding: 3rem 0 !important;
  img.backgroundSource {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    object-fit: contain;
  }
  .sectionContainer {
    @extend .innerContainer;
    position: relative;
    z-index: 200;
    display: flex;
    justify-content: center;
    .plansWrap {
      width: 90%;
      max-width: 800px;
      display: flex;
      flex-direction: column;
      .heading {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 1rem;
        color: $text-primary;
        display: none;
      }
      // PLANHEADER
      .planHeader {
        display: grid;
        grid-template-columns: 300px repeat(2, 1fr);
        text-align: center;
        .item {
          background: #f5f5f5;
          padding: 1rem 0;
          &:first-of-type {
            background: transparent;
            border-top: none;
            position: relative;
            img {
              width: 100px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
          }
          &.basic {
            border-top-left-radius: $radius;
            border-right: $border;
            border-top: $border;
            border-left: $border;
          }
          &.premimum {
            border-top-right-radius: $radius;
            border-right: $border;
            border-top: $border;
          }
          .membershipShild {
            text-align: center;
            margin-bottom: 0.25rem;
            display: none;
          }
          .plan {
            display: flex;
            flex-direction: column;
            row-gap: 0.25rem;
            small.discount {
              display: block;
              font-size: 0.75rem;
              span {
                text-decoration: line-through;
                font-weight: 400;
                color: $text-error;
              }
            }
            strong {
              font-size: 1rem;
              font-weight: 600;
              & + span.price {
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 1;
                strong {
                  text-decoration: line-through;
                  color: $text-error;
                  font-weight: 600;
                  font-size: 1.75rem;
                }
                & + span.text {
                  margin-bottom: 0;
                  & + small.text01 {
                    margin: 0 1rem 0.5rem 1rem;
                    color: $text-primary;
                    font-style: italic;
                  }
                }
              }
            }
            a.enroll_now {
              font-size: 0.75rem;
              display: inline-block;
              background: $bg-secondary;
              color: #fff;
              padding: 0.25rem 0.75rem;
              border-radius: 2rem;
              text-decoration: none;
              &:hover {
                background: darken($bg-secondary, $amount: 5%);
              }
            }
            & + ul {
              display: none;
            }
          }
        }
      }
      // BODY CONTAINER
      .bodyContainer {
        background: #f5f5f5;
        border: $border;
        border-radius: $radius;
        border-top-right-radius: 0;
        .section {
          position: relative;
          &.featuresWrap {
            background: #fff;
            border-radius: $radius;
            border-top-right-radius: 0;
            border-bottom: $border;
            .features {
              display: grid;
              grid-template-columns: 300px repeat(2, 1fr);
              align-items: center;
              border-bottom: $border;
              &:last-of-type {
                border-bottom: none;
              }
              .item {
                padding: 0.5rem 0.5rem;
                font-size: 0.75rem;
                text-align: center;
                border-right: $border;
                &.title {
                  text-align: left;
                  span {
                    color: #dd3333;
                    font-style: italic;
                  }
                }
                img {
                  width: 16px;
                }
              }
            }
          }
          &.include {
            background: #f5f5f5;
            padding: 1rem;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            h6 {
              text-transform: uppercase;
              margin-bottom: 0.5rem;
              & + ul {
                list-style-type: decimal;
                margin-bottom: 0;
                font-size: 0.75rem;
              }
            }
          }
        }
      }
      .singIn {
        text-align: center;
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        a {
          display: inline-block;
          padding: 0.25rem 0.75rem;
          background: $bg-secondary;
          color: #fff;
          border-radius: 1rem;
          text-decoration: none;
          &:hover {
            background: darken($bg-secondary, $amount: 5%);
          }
        }
      }
      .linkSignIn {
        text-align: center;
        font-size: 16px;
        a {
          color: $text-primary;
          font-weight: 600;
          text-decoration: underline;
          &:hover {
            color:#0563C1
          }
        }
        @include media-max(767px) {
          text-align: center;
          margin-left:0
        }
      }
      @include media-max(767px) {
        width: 100%;
        .heading {
          display: block;
        }
        .planHeader {
          display: flex;
          flex-direction: column-reverse;
          border: $border;
          background: #f5f5f5;
          .item {
            background: #f5f5f5;
            padding: 1rem 0;
            border: none !important;
            border-radius: 0 !important;
            &:last-of-type {
              background: #fff;
            }
            &:first-of-type {
              position: relative;
              display: none;
              img {
                position: static;
                transform: unset;
              }
            }
            .membershipShild {
              display: block;
            }
            .plan {
              position: relative;
              strong {
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 0.5;
              }
              a {
                font-size: 0.875rem;
                display: inline-block;
                background: $bg-secondary;
                color: #fff;
                padding: 0.25rem 0.75rem;
                border-radius: 2rem;
                text-decoration: none;
              }
              & + ul {
                display: block;
                list-style-type: none;
                text-align: left;
                padding: 0 1rem;
                border-top: $border;
                margin-top: 1rem;
                padding-top: 1rem;
                margin-bottom: 0;
                li {
                  padding-left: 0.75rem;
                  line-height: 1.75;
                  position: relative;
                  &:before {
                    content: "\f105";
                    position: absolute;
                    font-family: "FontAwesome";
                    left: 0;
                    color: $text-secondary;
                  }
                  &.heading {
                    font-weight: 500;
                    padding-bottom: 0.25rem;
                    text-transform: uppercase;
                    color: $text-secondary;
                    padding-left: 0;
                    &:before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        .bodyContainer {
          display: none;
        }
      }
    }
  }
  @include media-max(767px) {
    padding: 1rem !important;
    margin-top: 1rem;
    .sectionContainer {
      padding: 0 !important;
    }
  }
}
