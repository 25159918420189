@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
.wrapContainer, .templateBody .sourceTemplate .sectionWrap, .templateBody, .wrapper, .enrollmentPlans, .newsletterSubscription, .registration, .profilDetail, .explore, .underConstruction, .pressReleases, .paymentInstruction, .podcast_wrapper, .policiesContent, .homeTemplate .hone_banner_02, .homeTemplate .section_wrap, .homeTemplate, .alertMessage, .breadCrumbWrap, .secondaryHeader, .navigationWrap, .footerWrap, .headerWrap {
  width: 100%;
  float: left;
}

.innerContainer, .templateBody .sourceTemplate .sectionWrap .sectionContainer, .enrollmentPlans .sectionContainer, .topics, .contact, .pressReleases .container, .paymentInstruction .instructioncontainer, .testimonialWrap, .faqsWrap, .podcast_wrapper .sectionContainer, .podcast_wrapper .banner .container, .gridPost, .homeTemplate .section_wrap .section, .alertMessage .container, .breadCrumbWrap .breadCrumb, .secondaryHeader .pageTitle, .navigationWrap .navigation, .footerWrap .footer, .headerWrap .header {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0rem 1rem;
}

.transparent_body_text {
  color: rgba(0, 0, 0, 0.4);
}

.transparent_primary_text {
  color: rgba(8, 47, 88, 0.8);
}

.hyperlink {
  text-decoration: underline;
  color: #dd3333;
}

.dataForm {
  width: 100%;
  float: left;
}
.dataForm .data_row {
  display: flex;
  grid-column-gap: 1.25rem;
}
.dataForm .data_row .inputField {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.25rem;
  margin-bottom: 0.75rem;
}
.dataForm .data_row .inputField label {
  font-weight: 400;
  position: relative;
}
.dataForm .data_row .inputField small.help_text {
  font-size: 0.75rem;
}
.dataForm .data_row .inputField .erroralert {
  font-size: 0.75rem;
  color: #cc3300;
}
.dataForm .data_row .inputField input.is-invalid {
  border: 1px solid #dd3333;
}
@media only screen and (max-width: 767px) {
  .dataForm .data_row {
    flex-direction: column;
  }
}
.dataForm .inputButton {
  margin-bottom: 0;
}
.dataForm button[type=submit] {
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  transition: all ease 0.4s;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #0A670A;
  color: #fff;
  position: relative;
  padding: 0.5rem 0.75rem;
  padding-left: 2.25rem;
  cursor: pointer;
}
.dataForm button[type=submit] img {
  width: 22px;
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.dataForm button[type=submit]:hover {
  background: #339900;
}
.dataForm.action_button {
  padding-top: 0.75rem;
}

* {
  padding: 0;
  margin: 0;
}

*, *::after, *::before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-synthesis: antialiased;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
}

html, body {
  min-height: 100%;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

h1 small, h2 small, h3 small,
.h1 small, .h2 small, .h3 small {
  font-weight: normal;
  font-size: 75%;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-position: inside;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 1rem;
}

blockquote {
  margin: 0 0 1rem;
}

a {
  color: #082F58;
  text-decoration: none;
  transition: all ease 0.4s;
}
a:hover {
  text-decoration: underline;
  color: #041629;
}

hr {
  height: 1px;
  border: none;
  background: #b7c1c4;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

small {
  font-size: 85%;
}

.inputField {
  width: 100%;
  margin-bottom: 1rem;
}
.inputField input[type=text],
.inputField input[type=email],
.inputField input[type=password],
.inputField input[type=date],
.inputField input[type=number],
.inputField textarea {
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: all ease 0.4s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
.inputField input[type=text]:focus,
.inputField input[type=email]:focus,
.inputField input[type=password]:focus,
.inputField input[type=date]:focus,
.inputField input[type=number]:focus,
.inputField textarea:focus {
  outline: none;
}
.inputField input[type=text]:disabled,
.inputField input[type=email]:disabled,
.inputField input[type=password]:disabled,
.inputField input[type=date]:disabled,
.inputField input[type=number]:disabled,
.inputField textarea:disabled {
  background: #f6f6f6;
  cursor: not-allowed;
}
.inputField textarea {
  resize: none;
  height: 100%;
}
.inputField .submit-btn,
.inputField input[type=button],
.inputField input[type=submit] {
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: all ease 0.4s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: auto;
  background-color: #0A670A;
  border: 1px solid #0A670A;
  color: #fff;
  cursor: pointer;
  transition: all ease 0.4s;
}
.inputField .submit-btn:hover,
.inputField input[type=button]:hover,
.inputField input[type=submit]:hover {
  background-color: #053905;
}
.inputField .reset-btn,
.inputField input[type=reset] {
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: all ease 0.4s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #eaedee;
  border: 1px solid #dddcdb;
  color: #5c7986;
  cursor: pointer;
}
.inputField .reset-btn:hover,
.inputField input[type=reset]:hover {
  background-color: #ced5d7;
}
.inputField .selectbox {
  position: relative;
}
.inputField .selectbox select {
  width: 100%;
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: all ease 0.4s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.inputField .selectbox select ::-moz-selection {
  color: red;
}
.inputField .selectbox select ::selection {
  color: red;
}
.inputField .selectbox select:focus {
  outline: none;
}
.inputField .selectbox:before {
  content: "\f107";
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  font-size: 1.2rem;
  font-weight: 500;
  transform: translateY(-50%);
  z-index: 1;
}
.inputField .calendar {
  position: relative;
}
.inputField .calendar:before {
  content: "\f073";
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  font-weight: 500;
  transform: translateY(-50%);
  z-index: 1;
}
.inputField .input_group {
  display: inline-flex;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: inherit;
  border: 1px solid #dddcdb;
  background: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.inputField .input_group label[for=group_item] {
  display: flex;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
  align-items: center;
}

.headerWrap {
  padding: 1rem 0;
}
.headerWrap .header {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  align-items: center;
}
.headerWrap .header .logo {
  margin-right: auto;
}
.headerWrap .header .logo img {
  max-width: 300px;
}
.headerWrap .header .sign-in, .headerWrap .header .contact-us {
  position: relative;
}
.headerWrap .header .sign-in a, .headerWrap .header .contact-us a {
  display: inline-block;
  background: #082F58;
  padding: 0.5rem 0.75rem;
  color: #fff;
  border-radius: 0.5rem;
  text-decoration: none;
}
.headerWrap .header .sign-in a i.fa, .headerWrap .header .contact-us a i.fa {
  margin-right: 0.25rem;
}
.headerWrap .header .sign-in a:hover, .headerWrap .header .contact-us a:hover {
  background: #67bf67;
  color: #0A670A;
}
.headerWrap .header .navIcon {
  display: none;
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 3000;
}
.headerWrap .header .navIcon button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
}
.headerWrap .header .navIcon button i.fa {
  font-size: 1.5rem;
  color: #0A670A;
}
@media only screen and (max-width: 767px) {
  .headerWrap {
    padding: 0.25rem 0 0.5rem;
  }
  .headerWrap .header {
    display: grid;
    grid-template-columns: max-content auto;
    justify-content: space-between;
    row-gap: 0.5rem;
  }
  .headerWrap .header .logo img {
    max-width: 280px;
  }
  .headerWrap .header .search {
    display: none;
  }
  .headerWrap .header .sign-in {
    grid-column: 1/3;
    text-align: center;
    padding-bottom: 0.75rem;
  }
  .headerWrap .header .sign-in a {
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    font-size: 0.75rem;
  }
  .headerWrap .header .navIcon {
    display: block;
  }
}

.footerWrap {
  padding: 1.75rem 0;
  position: relative;
  background: #082F58;
  margin-top: auto;
}
.footerWrap .footer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.footerWrap .footer .explore_links {
  display: grid;
  grid-template-columns: 450px auto;
  align-items: center;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.footerWrap .footer .explore_links .supported {
  display: flex;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  justify-content: space-evenly;
}
.footerWrap .footer .explore_links .sitemap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.footerWrap .footer .explore_links .sitemap .nav_item {
  position: relative;
}
.footerWrap .footer .explore_links .sitemap .nav_item h5 {
  margin-bottom: 0.75rem;
  color: #8BCE8B;
  font-weight: 400;
}
.footerWrap .footer .explore_links .sitemap .nav_item h5 + ul {
  list-style-type: none;
  margin: 0;
}
.footerWrap .footer .explore_links .sitemap .nav_item h5 + ul li {
  position: relative;
  padding-bottom: 0.25rem;
}
.footerWrap .footer .explore_links .sitemap .nav_item h5 + ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
}
.footerWrap .footer .explore_links .sitemap .nav_item h5 + ul li a:hover {
  padding-left: 0.25rem;
  color: #8BCE8B;
}
.footerWrap .footer .explore_media {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  position: relative;
}
.footerWrap .footer .explore_media img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.footerWrap .footer .explore_media a {
  display: inline-block;
  background: #8BCE8B;
  padding: 0.25rem 0.75rem;
  color: #fff;
  border-radius: 2rem;
  color: #0A670A;
  font-weight: 500;
  text-decoration: none;
}
.footerWrap .footer .explore_media a i.fa {
  margin-right: 0.25rem;
}
.footerWrap .footer .explore_media a:hover {
  background: #67bf67;
  color: #0A670A;
}
.footerWrap .footer .quick_contact {
  display: flex;
  padding: 0.75rem 0;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
}
.footerWrap .footer .quick_contact a {
  color: #fff;
  text-decoration: none;
}
.footerWrap .footer .quick_contact a:hover {
  color: #8BCE8B;
}
.footerWrap .footer .quick_contact .social_profile {
  position: relative;
}
.footerWrap .footer .quick_contact .social_profile ul {
  list-style-type: none;
  display: flex;
  -moz-column-gap: 1.75rem;
       column-gap: 1.75rem;
  margin: 0;
}
.footerWrap .footer .quick_contact .social_profile ul li {
  position: relative;
}
.footerWrap .footer .quick_contact .social_profile ul li a {
  display: inline-block;
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
}
.footerWrap .footer .quick_contact .social_profile ul li a:hover {
  color: #8BCE8B;
}
@media only screen and (max-width: 767px) {
  .footerWrap {
    padding: 1rem 0;
  }
  .footerWrap .footer {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
  .footerWrap .footer .explore_links {
    display: flex;
    flex-direction: column;
  }
  .footerWrap .footer .explore_links .sitemap {
    padding-top: 0.75rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .footerWrap .footer .explore_links .sitemap .nav_item {
    position: relative;
  }
  .footerWrap .footer .explore_links .sitemap .nav_item h5 {
    font-size: 0.875rem;
  }
  .footerWrap .footer .explore_links .sitemap .nav_item h5 + ul {
    font-size: 0.75rem;
  }
  .footerWrap .footer .explore_media {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    row-gap: 0.75rem;
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
  }
  .footerWrap .footer .explore_media img {
    display: none;
  }
  .footerWrap .footer .explore_media a:last-of-type {
    grid-column: 1/3;
  }
  .footerWrap .footer .quick_contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .footerWrap .footer .quick_contact .item.social_profile {
    margin-top: 0.5rem;
  }
  .footerWrap .footer .quick_contact .item.social_profile ul li a {
    font-size: 1.5rem;
  }
}

.navigationWrap {
  background: #082F58;
  color: #fff;
}
.navigationWrap .navigation {
  display: flex;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
  align-items: center;
  justify-content: center;
}
.navigationWrap .navigation ul {
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
}
.navigationWrap .navigation ul li {
  position: relative;
}
.navigationWrap .navigation ul li a {
  display: block;
  color: #fff;
  padding: 1.25rem 0.75rem;
  text-decoration: none;
}
.navigationWrap .navigation ul li a + ul {
  width: 170px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2000;
  background: #0A670A;
  flex-direction: column;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: none;
}
.navigationWrap .navigation ul li a + ul li {
  position: relative;
}
.navigationWrap .navigation ul li a + ul li:last-of-type {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.navigationWrap .navigation ul li a + ul li:last-of-type a {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.navigationWrap .navigation ul li a + ul li a {
  padding: 0.375rem 0.75rem;
  background: #0A670A;
}
.navigationWrap .navigation ul li a + ul li a:hover {
  background: #085008;
}
.navigationWrap .navigation ul li a.active, .navigationWrap .navigation ul li a:hover {
  background: #0A670A;
  color: #fff;
}
.navigationWrap .navigation ul li:hover {
  background: #0A670A;
}
.navigationWrap .navigation ul li:hover ul {
  display: flex;
}
.navigationWrap .navigation span.register a {
  display: inline-block;
  background: #0A670A;
  padding: 0.25rem 0.75rem;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.75rem;
}
.navigationWrap .navigation span.register a i.fa {
  margin-right: 0.25rem;
}
@media only screen and (max-width: 767px) {
  .navigationWrap {
    position: relative;
    background: none;
  }
  .navigationWrap .navigation {
    display: flex;
    flex-direction: column;
  }
  .navigationWrap .navigation ul {
    display: none;
  }
}

.secondaryHeader {
  height: 170px;
  overflow: hidden;
  color: #fff;
  position: relative;
  background: #062341;
}
.secondaryHeader img.banner {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translateX(-50%) translateY(-50%);
  margin-left: -1px;
}
.secondaryHeader:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(8, 47, 88, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
}
.secondaryHeader .pageTitle {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 300;
}
.secondaryHeader .pageTitle h1 {
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  font-size: 2rem;
  color: #0A670A;
}
@media only screen and (max-width: 992px) {
  .secondaryHeader {
    height: 130px;
  }
  .secondaryHeader img.banner {
    height: 100%;
  }
  .secondaryHeader .pageTitle h2 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .secondaryHeader {
    margin-top: 1rem;
    height: 170px;
  }
  .secondaryHeader .pageTitle {
    top: auto;
    left: 0;
    transform: none;
    bottom: 0;
    text-align: center;
    background: #082F58;
    color: #fff;
    padding: 0.5rem 0.25rem;
  }
  .secondaryHeader .pageTitle h1 {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.breadCrumbWrap {
  background: #ececec;
  padding: 0.75rem 0;
}
.breadCrumbWrap .breadCrumb ul {
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
}
.breadCrumbWrap .breadCrumb ul li {
  position: relative;
  text-transform: capitalize;
}
.breadCrumbWrap .breadCrumb ul li:after {
  content: "\f105";
  font-family: "FontAwesome";
  display: inline-block;
  margin: 0 0.5rem;
}
.breadCrumbWrap .breadCrumb ul li:last-of-type a {
  pointer-events: none;
  cursor: text;
}
.breadCrumbWrap .breadCrumb ul li:last-of-type:after {
  display: none;
}
@media only screen and (max-width: 767px) {
  .breadCrumbWrap {
    display: none;
  }
}

.errorLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.errorLayout .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errorLayout .container h4 {
  margin-bottom: 0.25rem;
}
.errorLayout .container h4 + p {
  margin-bottom: 1rem;
}

.alertMessage {
  text-align: center;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertMessage .container {
  text-align: center;
}
.alertMessage .container .alert {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
  text-align: left;
}
.alertMessage .container .alert .icon {
  position: relative;
  width: 50px;
  height: 50px;
}
.alertMessage .container .alert .icon img {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.alertMessage .container .alert .text {
  padding-right: 1rem;
}
.alertMessage .container .alert .text p {
  margin-bottom: 0;
}
.alertMessage .container .alert.success {
  color: #339900;
}
.alertMessage .container .alert.success a {
  text-decoration: underline;
  color: #339900;
}
.alertMessage .container .alert.error {
  color: #cc3300;
}
.alertMessage .container .alert.error a {
  text-decoration: underline;
  color: #cc3300;
}

.CookieConsent {
  position: relative;
}
.CookieConsent a {
  color: #81c784;
  text-decoration: underline;
}
.CookieConsent .cc_buttons {
  padding-bottom: 0 !important;
}
.CookieConsent .cc_buttons button {
  font-size: 0.75rem;
}
.CookieConsent .cc_body .cc_body form p.cc_text {
  padding-bottom: 0.25rem !important;
}

.HamburgerMenu {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.8);
}
.HamburgerMenu .menuContainer {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  background: #082F58;
  padding: 1rem;
}
.HamburgerMenu .menuContainer .menuTitle {
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 0;
}
.HamburgerMenu .menuContainer .navigation {
  height: 90vh;
  overflow-y: scroll;
  color: #fff;
}
.HamburgerMenu .menuContainer .navigation ul {
  list-style-type: none;
}
.HamburgerMenu .menuContainer .navigation ul li {
  position: relative;
}
.HamburgerMenu .menuContainer .navigation ul li a {
  color: #fff;
  display: block;
  padding: 2px 0;
}
.HamburgerMenu .menuContainer .navigation ul li ul {
  margin-bottom: 0.5rem;
}
.HamburgerMenu .menuContainer .navigation ul li ul li {
  padding-left: 0.5rem;
}
.HamburgerMenu .menuContainer .navigation ul li ul li a {
  font-weight: 400;
  font-size: 0.75rem;
  position: relative;
  padding: 1px 0;
  padding-left: 0.75rem;
}
.HamburgerMenu .menuContainer .navigation ul li ul li a:before {
  content: "- ";
  position: absolute;
  left: 0;
}

.cookiesAlert {
  background: #082F58 !important;
  font-weight: 400;
  display: flex;
  align-items: center !important;
}
.cookiesAlert a {
  color: #339900;
  text-decoration: underline;
}
.cookiesAlert button {
  border-radius: 2rem;
}

.CookieSettings {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.CookieSettings .settingContainer {
  width: 90%;
  max-width: 950px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.75rem;
}
.CookieSettings .settingContainer .section {
  padding: 1rem;
  background: #ececec;
  position: relative;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.CookieSettings .settingContainer .section:before {
  content: "";
  width: 25%;
  height: 0.5px;
  background: #082F58;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.CookieSettings .settingContainer .section h5 {
  margin-bottom: 0.25rem;
  color: #082F58;
  font-weight: 500;
}
.CookieSettings .settingContainer .section h5 + p {
  margin-bottom: 0;
  font-size: 0.75rem;
}
.CookieSettings .settingContainer .hander {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.CookieSettings .settingContainer .hander a {
  display: block;
  background: #339900;
  padding: 0.75rem;
  text-align: center;
  color: #fff;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.CookieSettings .settingContainer .hander a:hover {
  background: #2b8000;
}
.CookieSettings .settingContainer .settingtype {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: scroll;
}
.CookieSettings .settingContainer .settingtype .mangeCoockies {
  display: flex;
  flex-direction: column;
}
.CookieSettings .settingContainer .settingtype .mangeCoockies .title {
  background: #f1f1f1;
  border-bottom: 1px solid #dddcdb;
  padding: 0.5rem 0.75rem;
  position: relative;
  color: #082F58;
  font-weight: 500;
}
.CookieSettings .settingContainer .settingtype .mangeCoockies .title label {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  color: #000000;
}
.CookieSettings .settingContainer .settingtype .mangeCoockies .detail {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0A670A;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.referralmodal {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.8);
}
.referralmodal .referral_container {
  width: 90%;
  max-width: 700px;
  background: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
  border: 1px solid #dddcdb;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.referralmodal .referral_container a.close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  font-size: 1.25rem;
}
.referralmodal .referral_container h4 {
  margin-bottom: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #0A670A;
}
.referralmodal .referral_container h4 + p {
  margin-bottom: 0.75rem;
}
.referralmodal .referral_container h4 + p + ul {
  list-style-type: none;
}
.referralmodal .referral_container .read_more {
  padding: 1.25rem;
}
.referralmodal .referral_container .read_more a {
  display: inline-block;
  background: #0A670A;
  color: #fff;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
}
.referralmodal .referral_container .read_more + .highlight_logo {
  margin-bottom: 0rem;
}
.referralmodal .referral_container .read_more + .highlight_logo img {
  max-width: 300px;
}

.accordion {
  display: flex;
  flex-direction: column;
}
.accordion .accordion-item {
  display: flex;
  flex-direction: column;
}
.accordion .accordion-item .accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dddcdb;
  padding: 0.5rem 0;
  font-weight: 500;
  color: #0A670A;
  font-size: 1rem;
  cursor: pointer;
}
.accordion .accordion-item .accordion-content {
  position: relative;
}
.accordion .accordion-item .accordion-content a {
  text-decoration: underline;
}
.accordion .accordion-item .accordion-content ul {
  list-style-type: none;
}
.accordion .accordion-item .accordion-content ul li {
  padding-left: 1rem;
  position: relative;
}
.accordion .accordion-item .accordion-content ul li:before {
  content: "\f105";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
}
.accordion .accordion-item .accordion-content ul#dosingwork {
  position: relative;
}
.accordion .accordion-item .accordion-content ul#dosingwork li {
  padding-left: 0;
  display: grid;
  grid-template-columns: 50px auto;
  padding-bottom: 2px;
}
.accordion .accordion-item .accordion-content ul#dosingwork li:before {
  display: none;
}
.accordion .accordion-item .accordion-content ul#dosingwork li span {
  font-weight: 500;
}
.accordion .accordion-item .accordion-content .stateTable {
  display: flex;
  flex-direction: column;
}
.accordion .accordion-item .accordion-content .stateTable strong {
  font-weight: 500;
  margin-bottom: 1rem;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid #dddcdb;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow ul {
  list-style-type: none;
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow ul li {
  position: relative;
  border-bottom: 1px solid #dddcdb;
  padding: 0.25rem 0.75rem;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow ul li:last-of-type {
  border-bottom: none;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow ul li:before {
  display: none;
}
.accordion .accordion-item .accordion-content .stateTable .tablerow + span {
  display: block;
  margin-bottom: 1rem;
  padding-top: 1rem;
  font-weight: 500;
}
.accordion .accordion-item .accordion-content .stateListing {
  display: flex;
  flex-direction: column;
}
.accordion .accordion-item .accordion-content .stateListing strong {
  font-weight: 500;
  margin-bottom: 1rem;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow {
  display: grid;
  grid-template-columns: 200px auto;
  border: 1px solid #dddcdb;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow:last-of-type {
  border-bottom: navy;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow.header {
  border-bottom: none;
  font-weight: 600;
  background: #f6f6f6;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow.header span {
  display: block;
  padding: 0.5rem 0.75rem;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn {
  position: relative;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn ul {
  list-style-type: none;
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn ul li {
  position: relative;
  border-bottom: 1px solid #dddcdb;
  padding: 0.25rem 0.75rem;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn ul li:last-of-type {
  border-bottom: none;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn ul li:before {
  display: none;
}
.accordion .accordion-item .accordion-content .stateListing .tablerow .tableColumn:first-of-type {
  font-weight: 500;
  background: #f1f1f1;
  border-right: 1px solid #dddcdb;
}
.accordion .accordion-item:first-of-type {
  position: relative;
}
.accordion .accordion-item:first-of-type .accordion-title {
  border-top: none;
}

.homeTemplate {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  display: flex;
  flex-direction: column;
}
.homeTemplate .section_wrap {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .section_wrap .section {
    padding: 0;
  }
}
.homeTemplate .promotionalBanner {
  height: 210px;
}
.homeTemplate .promotionalBanner .section {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.homeTemplate .promotionalBanner .section .banner_text {
  display: flex;
  flex-direction: column;
  color: #082F58;
}
.homeTemplate .promotionalBanner .section .banner_text h6 {
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.homeTemplate .promotionalBanner .section .banner_text h6 + .text_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}
.homeTemplate .promotionalBanner .section .banner_text h6 + .text_wrap p {
  margin-bottom: 0;
}
.homeTemplate .promotionalBanner .section .banner_text h6 + .text_wrap span {
  font-size: 1.25rem;
  font-weight: 500;
}
.homeTemplate .promotionalBanner .section .banner_text h6 + .text_wrap span + span {
  font-size: 1.25rem;
  font-weight: 400;
}
.homeTemplate .promotionalBanner .section .banner_text h6 + .text_wrap span + span small {
  font-size: 0.875rem;
  display: block;
  color: #cc3300;
  font-style: italic;
  padding-top: 0.25rem;
}
.homeTemplate .promotionalBanner .section .code_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeTemplate .promotionalBanner .section .code_text span.code {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  color: #082F58;
}
.homeTemplate .promotionalBanner .section .code_text span.code small {
  display: block;
  font-size: 0.75rem;
  font-size: 300;
}
.homeTemplate .promotionalBanner .section .code_text .pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeTemplate .promotionalBanner .section .code_text .pricing span {
  color: #cc3300;
  text-decoration: line-through;
}
.homeTemplate .promotionalBanner .section .code_text .pricing span + strong {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .promotionalBanner {
    height: auto;
    margin-top: 1rem;
  }
  .homeTemplate .promotionalBanner .section {
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }
  .homeTemplate .promotionalBanner .section .banner_text {
    text-align: center;
  }
}
.homeTemplate .hone_banner_02 {
  position: relative;
  text-align: center;
  height: 550px;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .hone_banner_02 {
    height: 400px;
  }
}
.homeTemplate .hone_banner_02 img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.homeTemplate .hone_banner_02 .section_wrap {
  position: relative;
  z-index: 1000;
  height: 100%;
}
.homeTemplate .hone_banner_02 .section_wrap .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.homeTemplate .hone_banner_02 .section_wrap .section span {
  font-size: 1.75rem;
  font-weight: 600;
  text-align: left;
  color: #1F353D;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.text-01 {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.text-02 {
  line-height: 1.2;
  margin-bottom: 1.75rem;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.text-02 span {
  display: block;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.action {
  position: relative;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.action a {
  display: inline-block;
  border: 1px solid #082F58;
  border-radius: 0.25rem;
  background: #fff;
  color: #082F58;
  padding: 0.75rem 1rem;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.25rem;
}
.homeTemplate .hone_banner_02 .section_wrap .section span.action a:hover {
  background: #082F58;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .hone_banner_02 .section_wrap .section {
    text-align: center;
    align-items: center;
  }
  .homeTemplate .hone_banner_02 .section_wrap .section span {
    font-size: 1.25rem;
    text-align: center;
  }
  .homeTemplate .hone_banner_02 .section_wrap .section span.text-01 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  .homeTemplate .hone_banner_02 .section_wrap .section span.text-02 {
    line-height: 1.2;
    margin-bottom: 1.75rem;
  }
  .homeTemplate .hone_banner_02 .section_wrap .section span.text-02 span {
    display: block;
  }
}
.homeTemplate .discountPrograms {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 2rem;
}
.homeTemplate .discountPrograms .sectionTitle {
  position: relative;
  margin-bottom: 2rem;
}
.homeTemplate .discountPrograms .sectionTitle h1 {
  color: #082F58;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
}
.homeTemplate .discountPrograms .sectionTitle h1 + span {
  font-size: 2rem;
  display: block;
  color: #082F58;
  font-weight: 400;
}
.homeTemplate .discountPrograms .sectionTitle h1 + span span {
  display: block;
}
.homeTemplate .discountPrograms .membershipCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  align-self: center;
}
.homeTemplate .discountPrograms .membershipCard .item {
  display: flex;
  justify-content: flex-start;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic {
  width: 350px;
  position: relative;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic > img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic + .content {
  position: relative;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic + .content h6 {
  margin-bottom: 1rem;
  color: #082F58;
  font-weight: 600;
  font-size: 1.5rem;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic + .content h6 + p {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic + .content h6 + p + a {
  display: inline-block;
  border-radius: 0.25rem;
  background: #082F58;
  color: #fff;
  padding: 0.75rem 1rem;
  font-weight: 600;
  text-decoration: none;
}
.homeTemplate .discountPrograms .membershipCard .item .graphic + .content h6 + p + a:hover {
  background: #0A670A;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .discountPrograms .membershipCard .item {
    display: flex;
    flex-direction: column;
  }
}
.homeTemplate .discountPrograms .whyUs {
  position: relative;
  display: flex;
  padding: 1rem 0;
  margin-bottom: 2rem;
}
.homeTemplate .discountPrograms .whyUs ul {
  width: 100%;
  max-width: 950px;
  display: inline-block;
  list-style-type: none;
  margin: 0;
}
.homeTemplate .discountPrograms .whyUs ul li {
  padding: 0.25rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #082F58;
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.homeTemplate .discountPrograms .whyUs ul li a {
  width: 100px;
  border-radius: 0.25rem;
  background: #082F58;
  color: #fff;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
}
.homeTemplate .discountPrograms .whyUs ul li a:hover {
  background: #0A670A;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .discountPrograms {
    padding-top: 1rem;
  }
  .homeTemplate .discountPrograms .sectionTitle {
    margin-bottom: 1rem;
    padding: 0rem 1rem;
  }
  .homeTemplate .discountPrograms .sectionTitle h1 {
    font-size: 1.75rem;
  }
  .homeTemplate .discountPrograms .sectionTitle h1 + span {
    font-size: 1rem;
  }
  .homeTemplate .discountPrograms .membershipCard {
    display: flex;
    flex-direction: column;
    -moz-column-gap: 0;
         column-gap: 0;
    margin-bottom: 0;
  }
  .homeTemplate .discountPrograms .membershipCard .item {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    margin-bottom: 0rem;
  }
  .homeTemplate .discountPrograms .membershipCard .item .graphic {
    width: 100%;
  }
  .homeTemplate .discountPrograms .membershipCard .item .content {
    text-align: center;
    padding: 1rem;
  }
  .homeTemplate .discountPrograms .whyUs {
    background: #f1f1f1;
    margin-bottom: 0;
  }
  .homeTemplate .discountPrograms .whyUs ul {
    margin: 0;
  }
  .homeTemplate .discountPrograms .whyUs ul li {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    border-bottom: 1px solid #dddcdb;
    padding-bottom: 1rem;
    text-align: center;
  }
  .homeTemplate .discountPrograms .whyUs ul li:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
  .homeTemplate .discountPrograms .whyUs ul li span {
    width: auto;
    background: none;
    color: #082F58;
    font-weight: 500;
    padding: 0.25rem;
  }
}
.homeTemplate .staticesWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.homeTemplate .staticesWrap img.banner {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translateX(-50%) translateY(-50%);
}
.homeTemplate .staticesWrap .section {
  display: flex;
  justify-content: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  padding: 2rem 0;
  position: relative;
  z-index: 1000;
}
.homeTemplate .staticesWrap .section .item {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #203342;
  position: relative;
  padding: 0 2rem;
}
.homeTemplate .staticesWrap .section .item:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #203342;
  position: absolute;
  left: 0;
  top: 0;
}
.homeTemplate .staticesWrap .section .item:first-of-type:before {
  display: none;
}
.homeTemplate .staticesWrap .section .item span {
  font-size: 5rem;
  font-weight: 800;
}
.homeTemplate .staticesWrap .section .item span + strong {
  font-size: 1.5rem;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .staticesWrap {
    height: auto;
  }
  .homeTemplate .staticesWrap img.banner {
    height: 100%;
  }
  .homeTemplate .staticesWrap .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    -moz-column-gap: 0;
         column-gap: 0;
    padding: 0;
  }
  .homeTemplate .staticesWrap .section .item {
    width: auto;
    border-bottom: 2px solid #203342;
    padding: 2rem;
    padding-top: 1.25rem;
  }
  .homeTemplate .staticesWrap .section .item:last-of-type {
    border-bottom: none;
  }
  .homeTemplate .staticesWrap .section .item:before {
    display: none;
  }
  .homeTemplate .staticesWrap .section .item span {
    font-size: 3rem;
    font-weight: 800;
  }
  .homeTemplate .staticesWrap .section .item span + strong {
    font-size: 1rem;
    font-weight: 600;
  }
}
.homeTemplate .quickExplore {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}
.homeTemplate .quickExplore .item {
  width: 300px;
  position: relative;
}
.homeTemplate .quickExplore .item .card {
  display: flex;
  flex-direction: column;
  height: calc(400px + 1rem);
}
.homeTemplate .quickExplore .item .card .graphic {
  position: relative;
  height: 200px;
  overflow: hidden;
}
.homeTemplate .quickExplore .item .card .graphic > img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  transition: all ease 0.4s;
}
.homeTemplate .quickExplore .item .card .cardInfo {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 1rem 2rem;
}
.homeTemplate .quickExplore .item .card .cardInfo span {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #082F58;
  font-weight: 600;
}
.homeTemplate .quickExplore .item .card .cardInfo span + a {
  border: 1px solid #082F58;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #082F58;
  text-decoration: none;
  font-size: 1rem;
}
.homeTemplate .quickExplore .item .card .cardInfo span + a:hover {
  background: #082F58;
  color: #fff;
}
.homeTemplate .quickExplore .item .card:hover {
  position: relative;
}
.homeTemplate .quickExplore .item .card:hover .graphic {
  position: relative;
}
.homeTemplate .quickExplore .item .card:hover .graphic img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}
@media only screen and (max-width: 767px) {
  .homeTemplate .quickExplore {
    flex-direction: column;
    padding: 0;
  }
  .homeTemplate .quickExplore .item {
    width: auto;
    position: relative;
  }
  .homeTemplate .quickExplore .item .card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
  }
  .homeTemplate .quickExplore .item .card .graphic {
    height: auto;
    order: 0;
  }
  .homeTemplate .quickExplore .item .card .cardInfo {
    height: auto;
    order: 1;
  }
}
.homeTemplate .cardRegistration {
  background: #e7e7e7;
  position: relative;
  padding: 5rem 0;
}
.homeTemplate .cardRegistration .advertisement {
  display: flex;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  justify-content: center;
}
.homeTemplate .cardRegistration .advertisement .graphic {
  width: 400px;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info {
  width: 500px;
  color: #082F58;
  display: flex;
  flex-direction: column;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info h4 {
  margin-bottom: 1rem;
  font-weight: 400;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info h4 span {
  display: block;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info h4 + strong {
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info .handler {
  position: relative;
  display: block;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info .handler a {
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  font-weight: 600;
  background: #082F58;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.homeTemplate .cardRegistration .advertisement .advertisement_info .handler a:hover {
  background: #0A670A;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .cardRegistration {
    padding: 0;
  }
  .homeTemplate .cardRegistration .advertisement {
    flex-direction: column;
    -moz-column-gap: 0;
         column-gap: 0;
  }
  .homeTemplate .cardRegistration .advertisement .graphic {
    width: auto;
  }
  .homeTemplate .cardRegistration .advertisement .advertisement_info {
    width: auto;
    padding: 1rem;
    padding-bottom: 1.75rem;
    text-align: center;
  }
  .homeTemplate .cardRegistration .advertisement .advertisement_info h4 {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
  .homeTemplate .cardRegistration .advertisement .advertisement_info h4 span {
    display: inline-block;
  }
  .homeTemplate .cardRegistration .advertisement .advertisement_info h4 + strong {
    margin-bottom: 0.75rem;
  }
}
.homeTemplate .IntroductionVideo {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem !important;
}
.homeTemplate .IntroductionVideo .introText {
  width: 100%;
  max-width: 950px;
  position: relative;
  padding: 1rem;
  font-size: 1rem;
  font-style: italic;
}
.homeTemplate .IntroductionVideo .videoContainer {
  position: relative;
  width: 100%;
  max-width: 950px;
  height: 550px;
}
.homeTemplate .IntroductionVideo .videoContainer video {
  width: 100%;
  height: auto;
  border-radius: 1rem;
  border: 1px solid #dddcdb;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .IntroductionVideo .videoContainer {
    height: auto;
    padding: 1rem;
  }
  .homeTemplate .IntroductionVideo .videoContainer video {
    width: 100%;
    height: auto;
  }
}
.homeTemplate .collaborative {
  display: flex;
  flex-direction: column;
}
.homeTemplate .collaborative .collaborative_title {
  background: #a6c0a7;
  text-align: center;
  padding: 1rem;
  text-align: center;
  color: #000004;
  font-size: 1.75rem;
  font-weight: 800;
}
.homeTemplate .collaborative .partnerships {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  padding: 2rem 0;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .collaborative .partnerships {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}
.homeTemplate .exploreFeatures {
  display: flex;
  justify-content: center;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  margin-bottom: 2rem;
}
.homeTemplate .exploreFeatures a {
  width: 250px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #082F58;
  color: #d1deca;
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: 600;
}
.homeTemplate .exploreFeatures a span {
  display: block;
}
.homeTemplate .exploreFeatures a:hover {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .homeTemplate .exploreFeatures {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
  .homeTemplate .exploreFeatures a {
    display: block;
    width: auto;
    height: auto;
    border-bottom: 1px solid #dddcdb;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.25rem 0;
  }
  .homeTemplate .exploreFeatures a span {
    display: inline-block;
  }
}

.carousel-root .carousel .slide {
  text-align: left !important;
}
.carousel-root .carousel .slide:hover {
  cursor: text;
}
.carousel-root .control-dots {
  text-align: right;
  padding: 0 2rem;
}
.carousel-root .control-dots .dot {
  background: #fff;
  width: 8px;
  height: 8px;
  opacity: 1;
  box-shadow: none;
  margin: 0 6px;
}
.carousel-root .control-dots .dot.selected {
  background: #0A670A;
}

.team_wrapper {
  display: flex;
  flex-direction: column;
}
.team_wrapper .section_title {
  position: relative;
  margin-bottom: 0.5rem;
}
.team_wrapper .section_title h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-family: "Gelasio", serif;
}
.team_wrapper .section_title h3 + p {
  color: #0b4237;
}
.team_wrapper .teamWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: auto;
  grid-gap: 1.25rem;
}
.team_wrapper .teamWrap .team {
  position: relative;
  background: #fff;
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
}
.team_wrapper .teamWrap .team .imgBox {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 1rem;
}
.team_wrapper .teamWrap .team .imgBox img {
  width: 100%;
  transition: all ease 0.4s;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-radius: 100%;
}
.team_wrapper .teamWrap .team .infoBox {
  padding: 0.5rem;
  position: relative;
}
.team_wrapper .teamWrap .team .infoBox h6 {
  color: #082F58;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}
.team_wrapper .teamWrap .team .infoBox h6 + span {
  display: block;
  font-size: 0.75rem;
}
.team_wrapper .teamWrap .team .infoBox:before {
  content: "";
  width: 0;
  height: 2px;
  background: #082F58;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  transition: all ease 0.4s;
}
.team_wrapper .teamWrap .team:hover .infoBox:before {
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .team_wrapper .teamWrap {
    grid-template-columns: repeat(3, 1fr);
  }
  .team_wrapper .teamWrap .team .infoBox::before {
    width: 70%;
  }
}
@media only screen and (max-width: 767px) {
  .team_wrapper .teamWrap {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    padding: 0;
  }
  .team_wrapper .teamWrap .team .infoBox {
    background: #f1f1f1;
    padding: 1rem;
    text-align: center;
    position: relative;
  }
  .team_wrapper .teamWrap .team .infoBox span {
    padding-bottom: 0;
  }
}

.gridPost {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
}
.gridPost .item {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ececec;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
}
.gridPost .item a {
  display: flex;
  flex-direction: column;
}
.gridPost .item a .thumbnail {
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
}
.gridPost .item a .thumbnail img {
  transition: all ease 0.4s;
}
.gridPost .item a .title {
  padding: 0.75rem;
}
.gridPost .item a .title small {
  color: #082F58;
}
.gridPost .item a .title small + p {
  margin-bottom: 0;
  font-weight: 400;
}
.gridPost .item a:hover {
  text-decoration: none;
}
.gridPost .item a:hover .thumbnail img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  filter: gray;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
}
.gridPost .norecordFound {
  grid-column: 1/8;
}
@media only screen and (max-width: 992px) {
  .gridPost {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .gridPost {
    display: flex;
    flex-direction: column;
  }
}

.policiesContent a {
  color: red;
  text-decoration: underline;
}
.policiesContent.cookie-policy {
  position: relative;
}
.policiesContent.cookie-policy h6 {
  color: #082F58;
  margin-bottom: 0.25rem;
}
.policiesContent.cookie-policy table {
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  border-spacing: 0;
}
.policiesContent.cookie-policy table tr {
  background: #fff;
}
.policiesContent.cookie-policy table tr th,
.policiesContent.cookie-policy table tr td {
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid #dddcdb;
}
.policiesContent.cookie-policy table tr th:first-of-type,
.policiesContent.cookie-policy table tr td:first-of-type {
  border-right: 1px solid #dddcdb;
}
.policiesContent.cookie-policy table tr th {
  padding: 0.75rem 0.75rem;
}
.policiesContent.cookie-policy table tr:nth-of-type(odd) {
  background: #f6f6f6;
}
.policiesContent.cookie-policy table tr:first-of-type th {
  background: #e9e9e9;
  color: #082F58;
}
.policiesContent.cookie-policy table tr:first-of-type th:first-of-type {
  border-top-left-radius: 0.25rem;
}
.policiesContent.cookie-policy table tr:first-of-type th:last-of-type {
  border-top-right-radius: 0.25rem;
}
.policiesContent.cookie-policy table tr:last-of-type td {
  border-bottom: none;
}
.policiesContent.cookie-policy table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0.25rem;
}
.policiesContent.cookie-policy table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0.25rem;
}
.policiesContent ol, .policiesContent ul {
  list-style-position: outside;
  margin-left: 1rem;
}

.podcast_wrapper {
  min-height: 500px;
  position: relative;
}
.podcast_wrapper .banner {
  position: relative;
  background: #043263;
}
.podcast_wrapper .banner .container img {
  max-width: auto;
}
.podcast_wrapper .sectionContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 0;
  position: relative;
  z-index: 1000;
}
.podcast_wrapper .sectionContainer .browse_episodes {
  color: #0A670A;
  margin-bottom: 1rem;
}
.podcast_wrapper .sectionContainer .browse_episodes h5 {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}
.podcast_wrapper .sectionContainer .browse_episodes h5 + p {
  margin-bottom: 0;
  color: #000000;
  font-style: italic;
}
.podcast_wrapper .sectionContainer .episodes_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes {
  display: grid;
  grid-template-columns: 200px auto;
  -moz-column-gap: 1.25rem;
       column-gap: 1.25rem;
  align-items: center;
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_thumbnail {
  position: relative;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_thumbnail img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_info {
  padding-right: 1rem;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_info a {
  display: inline-block;
  background: #0A670A;
  color: #fff;
  border-radius: 2rem;
  padding: 2px 0.75rem;
  padding-left: 1.75rem;
  position: relative;
  text-decoration: none;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_info a img {
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
}
.podcast_wrapper .sectionContainer .episodes_wrap .episodes .small_podcast_player {
  display: none;
}
@media only screen and (max-width: 767px) {
  .podcast_wrapper {
    min-height: auto;
    padding: 1rem;
  }
  .podcast_wrapper .sectionContainer {
    padding: 0rem 0;
  }
  .podcast_wrapper .sectionContainer .browse_episodes {
    margin-bottom: 0;
  }
  .podcast_wrapper .sectionContainer .episodes_wrap {
    row-gap: 1rem;
  }
  .podcast_wrapper .sectionContainer .episodes_wrap .episodes {
    display: grid;
    grid-template-columns: 150px auto;
  }
  .podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_thumbnail img {
    border-bottom-left-radius: 0;
  }
  .podcast_wrapper .sectionContainer .episodes_wrap .episodes .episodes_info .podcast_player {
    display: none;
  }
  .podcast_wrapper .sectionContainer .episodes_wrap .episodes .small_podcast_player {
    display: block;
    grid-column: 1/3;
    padding: 0.75rem;
  }
}

#podcastPlayer,
#devicepodcastPlayer {
  position: relative;
}
#podcastPlayer div.rhap_container,
#devicepodcastPlayer div.rhap_container {
  box-shadow: none;
  padding: 0;
}
#podcastPlayer div.rhap_container .rhap_main,
#devicepodcastPlayer div.rhap_container .rhap_main {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
#podcastPlayer div.rhap_container .rhap_main .rhap_controls-section,
#devicepodcastPlayer div.rhap_container .rhap_main .rhap_controls-section {
  flex: none;
  margin: 0;
}

.patient_quest {
  display: flex;
  flex-direction: column;
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
}
.patient_quest .acceptance {
  background: #f6f6f6;
  padding: 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.patient_quest .acceptance h5 {
  color: #0A670A;
  margin-bottom: 0.25rem;
}
.patient_quest .acceptance p {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}
.patient_quest .acceptance p:last-of-type {
  margin-bottom: 0;
}
.patient_quest .help_center {
  background: #f6f6f6;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.patient_quest .help_center .item {
  position: relative;
  padding-bottom: 0.75rem;
}
.patient_quest .help_center .item h6 {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  color: #082F58;
}
.patient_quest .help_center .item h6 + p {
  margin-bottom: 0;
}
.patient_quest .dataForm {
  background: #fff;
  padding: 1rem;
}

.faqsWrap {
  display: grid;
  grid-template-columns: 250px auto;
  align-items: flex-start;
}
.faqsWrap .faqs_categories {
  border: 1px solid #dddcdb;
  border-right: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.faqsWrap .faqs_categories ul {
  list-style-type: none;
  margin-bottom: 0;
}
.faqsWrap .faqs_categories ul li {
  position: relative;
}
.faqsWrap .faqs_categories ul li a {
  display: block;
  border-bottom: 1px solid #dddcdb;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  font-weight: 500;
  background: #fff;
}
.faqsWrap .faqs_categories ul li a.active, .faqsWrap .faqs_categories ul li a:hover {
  background: #0A670A;
  color: #fff;
}
.faqsWrap .faqs_categories ul li:last-of-type a {
  border-bottom-left-radius: 0.25rem;
  border-bottom: none;
}
.faqsWrap .faqs_categories ul li:first-of-type a {
  border-top-left-radius: 0.25rem;
}
.faqsWrap .FaqListing {
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  padding: 0.5rem 1rem;
  border-top-left-radius: 0;
}
.faqsWrap .faqs {
  position: relative;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
}
.faqsWrap .faqs:before {
  content: "";
  width: 100px;
  height: 0.5px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #0A670A;
}
.faqsWrap .faqs:last-of-type {
  padding-bottom: 0;
}
.faqsWrap .faqs:last-of-type:before {
  display: none;
}
.faqsWrap .faqs h6 {
  color: #0A670A;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}
.faqsWrap .faqs .answer {
  width: 100%;
  position: relative;
  padding-top: 0.25rem;
}
.faqsWrap .faqs .answer p {
  margin-bottom: 1rem;
}
.faqsWrap .faqs .answer p:last-of-type {
  margin-bottom: 0;
}
.faqsWrap .faqs .answer a {
  color: red;
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .faqsWrap {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0;
  }
  .faqsWrap .faqs_categories {
    border-right: 1px solid #dddcdb;
    border-radius: 0.25rem !important;
    width: 100%;
  }
  .faqsWrap .faqs_categories ul li {
    position: relative;
  }
  .faqsWrap .faqs_categories ul li:last-of-type a {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .faqsWrap .faqs_categories ul li:first-of-type a {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .faqsWrap .FaqListing {
    border-top-left-radius: 0.25rem;
  }
}

.testimonialWrap {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding: 1rem 0;
  position: relative;
}
.testimonialWrap .item {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  font-style: italic;
}
.testimonialWrap .item .signature {
  color: #082F58;
  font-style: normal;
}

.company_profile {
  position: relative;
  display: flex;
  flex-direction: column;
}
.company_profile blockquote {
  border: 1px solid #b0d2cb;
  background: #f8fffe;
  padding: 2rem;
  color: #0b4237;
  font-family: "Gelasio", serif;
  margin: 1rem 0 2rem;
}
.company_profile blockquote.bordered {
  border-left: 10px solid #b0d2cb;
}
.company_profile blockquote:before {
  content: "“";
  font-size: 4rem;
  color: #0b4237;
  display: block;
  margin-bottom: -50px;
  margin-top: -20px;
}
.company_profile blockquote .blockquote_text {
  font-style: italic;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}
.company_profile blockquote .blockquote_credit {
  text-align: right;
  margin: 0;
  font-weight: 800;
}
.company_profile blockquote .blockquote_credit:before {
  font-weight: 800;
  content: "— ";
}
.company_profile blockquote .blockquote_credit small {
  display: block;
  font-weight: 400;
}

.member_benefits {
  display: flex;
  flex-direction: column;
}
.member_benefits .membership_includes {
  position: relative;
}
.member_benefits .membership_includes a.switch_signup {
  color: red;
}
.member_benefits .membership_includes h6 {
  margin-bottom: 0.75rem;
  color: #0A670A;
}
.member_benefits .membership_includes h6 a {
  color: #0A670A;
  text-decoration: underline;
}
.member_benefits .membership_includes h6 a:hover {
  text-decoration: none;
}
.member_benefits .membership_includes h6 + ul {
  list-style-type: none;
  list-style-position: inside;
}
.member_benefits .membership_includes h6 + ul li {
  padding-left: 0.75rem;
  line-height: 1.75;
  position: relative;
}
.member_benefits .membership_includes h6 + ul li:before {
  content: "\f105";
  position: absolute;
  font-family: "FontAwesome";
  left: 0;
  color: #0A670A;
}
.member_benefits blockquote {
  border: 1px solid #b0d2cb;
  background: #f8fffe;
  padding: 2rem;
  color: #0b4237;
  font-family: "Gelasio", serif;
  margin: 1rem 0 2rem;
}
.member_benefits blockquote.bordered {
  border-left: 10px solid #b0d2cb;
}
.member_benefits blockquote:before {
  content: "“";
  font-size: 4rem;
  color: #0b4237;
  display: block;
  margin-bottom: -50px;
  margin-top: -20px;
}
.member_benefits blockquote .blockquote_text {
  font-style: italic;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}
.member_benefits blockquote .blockquote_credit {
  text-align: right;
  margin: 0;
  font-weight: 800;
}
.member_benefits blockquote .blockquote_credit:before {
  font-weight: 800;
  content: "— ";
}
.member_benefits blockquote .blockquote_credit small {
  display: block;
  font-weight: 400;
}
.member_benefits .page_footer {
  border: 1px solid #dddcdb;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.member_benefits .page_footer .switch_faq {
  position: relative;
}
.member_benefits .page_footer .switch_faq a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #0A670A;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.75rem;
}
.member_benefits .page_footer .switch_faq a i.fa {
  margin-right: 0.25rem;
}
.member_benefits .page_footer .switch_contact {
  font-style: italic;
}
.member_benefits .page_footer .switch_contact a {
  color: #0A670A;
  text-decoration: underline;
}

.paymentInstruction {
  padding: 2rem 0;
}
.paymentInstruction a {
  color: red;
  text-decoration: none;
}
.paymentInstruction .alertSuccess {
  position: relative;
  display: grid;
  grid-template-columns: 100px auto;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.paymentInstruction .alertSuccess .alertText {
  position: relative;
  margin-bottom: 1rem;
}
.paymentInstruction .alertSuccess .alertText h5 {
  margin-bottom: 0.25rem;
}
.paymentInstruction .alertSuccess .alertText h5 + p {
  margin-bottom: 0.5rem;
}
.paymentInstruction .alertSuccess .alertText h5 + p + h4 {
  margin-bottom: 0rem;
}
.paymentInstruction .alertSuccess .alertText h5 + p + h4 span {
  color: #0A670A;
  font-weight: 600;
}
.paymentInstruction .title {
  position: relative;
  margin-bottom: 0.75rem;
}
.paymentInstruction .title h5 {
  margin-bottom: 0.25rem;
}
.paymentInstruction .title h5 span {
  display: block;
  font-size: 0.875rem;
  padding-top: 0.25rem;
}
.paymentInstruction .title h5 + p {
  font-style: italic;
}
.paymentInstruction .instructioncontainer {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
.paymentInstruction .instructioncontainer .paymentmode {
  display: flex;
  flex-direction: column;
  border: 1px solid #dddcdb;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.paymentInstruction .instructioncontainer .paymentmode h6 {
  margin-bottom: 1rem;
  color: #082F58;
  font-weight: 600;
}
.paymentInstruction .instructioncontainer .paymentmode .payment {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
.paymentInstruction .instructioncontainer .paymentmode .payment:last-of-type {
  margin-bottom: 0;
}
.paymentInstruction .instructioncontainer .paymentmode .payment ul {
  list-style-type: none;
  margin-bottom: 0;
}

.pressReleases {
  padding: 2rem 0;
}
.pressReleases .container {
  display: grid;
  grid-template-columns: 350px auto;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.pressReleases .container a {
  color: red;
  text-decoration: underline;
}
.pressReleases .container .graphic {
  position: relative;
}
.pressReleases .container .graphic img {
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
@media only screen and (max-width: 767px) {
  .pressReleases .container {
    display: flex;
    flex-direction: column;
  }
  .pressReleases .container .graphic {
    margin-bottom: 1rem;
  }
  .pressReleases .container .graphic img {
    border-radius: 0 !important;
  }
}

.underConstruction {
  padding: 2rem;
  text-align: center;
}
.underConstruction img {
  max-width: 300px;
}

.affiliates {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
.affiliates img.backgroundSource {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.affiliates .sectionContainer {
  position: relative;
  z-index: 200;
}
.affiliates .sectionContainer .section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.affiliates .sectionContainer .section .sectionDetail {
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}
.affiliates .sectionContainer .section .sectionDetail .actionbutton {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 1rem;
}
.affiliates .sectionContainer .section .sectionDetail .actionbutton a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #082F58;
  color: #fff;
  border-radius: 1rem;
  text-decoration: none;
}
.affiliates .sectionContainer .section .sectionDetail .actionbutton a i.fa {
  margin-right: 0.25rem;
}
.affiliates .sectionContainer .section .sectionDetail .actionbutton a:hover {
  background: #0A670A;
}
.affiliates .sectionContainer .section.ardent {
  background: #d7d7e6;
}
.affiliates .sectionContainer .section.ardent .sectionDetail {
  position: relative;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .discount {
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #082F58;
  font-weight: 600;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .heading {
  font-size: 1.25rem;
  font-weight: 500;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .code {
  font-style: italic;
  margin-bottom: 1rem;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .code strong {
  font-size: 1rem;
  font-weight: 500;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .code + p {
  margin-bottom: 1rem;
}
.affiliates .sectionContainer .section.ardent .sectionDetail .code + p strong {
  display: block;
}
.affiliates .sectionContainer .section.nuggmd {
  background: #d7d7e6;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail {
  position: relative;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .heading-01 {
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #082F58;
  font-weight: 600;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .heading-02 {
  font-size: 1.25rem;
  font-weight: 500;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .text-01 {
  font-style: italic;
  margin-bottom: 1rem;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .text-01 strong {
  font-size: 1rem;
  font-weight: 500;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .text-01 + p {
  margin-bottom: 1rem;
}
.affiliates .sectionContainer .section.nuggmd .sectionDetail .text-01 + p strong {
  display: block;
}
@media only screen and (max-width: 767px) {
  .affiliates .sectionContainer {
    padding: 1.25rem 0 !important;
    padding-bottom: 0 !important;
  }
  .affiliates .sectionContainer .section {
    display: flex;
    flex-direction: column;
  }
  .affiliates .sectionContainer .section .sectionDetail {
    padding: 1rem !important;
  }
  .affiliates .sectionContainer .section.nuggmd {
    position: relative;
  }
  .affiliates .sectionContainer .section.nuggmd .sectionDetail {
    order: 2;
  }
  .affiliates .sectionContainer .section.ardent .sectionDetail {
    order: 2;
  }
}

.physician {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden;
}
.physician img.backgroundSource {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  -o-object-fit: contain;
     object-fit: contain;
}
.physician .sectionContainer {
  position: relative;
  z-index: 200;
}
.physician .sectionContainer .section {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  padding-left: 1rem;
}
.physician .sectionContainer .section .heading-01 {
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #082F58;
  font-weight: 600;
}
.physician .sectionContainer .section .heading-02 {
  font-size: 1.25rem;
  font-weight: 500;
}
.physician .sectionContainer .section .text-01 {
  font-style: italic;
  margin-bottom: 0.25rem;
}
.physician .sectionContainer .section .text-01 strong {
  font-size: 1rem;
  font-weight: 500;
}
.physician .sectionContainer .section .text-01 + p {
  margin-bottom: 1rem;
}
.physician .sectionContainer .section .text-01 + p strong {
  display: block;
}
.physician .sectionContainer .section .actionbutton {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 1rem;
}
.physician .sectionContainer .section .actionbutton a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #082F58;
  color: #fff;
  border-radius: 1rem;
  text-decoration: none;
}
.physician .sectionContainer .section .actionbutton a i.fa {
  margin-right: 0.25rem;
}
.physician .sectionContainer .section .actionbutton a:hover {
  background: #0A670A;
}
.physician .sectionContainer .registration {
  margin-top: 2rem;
}
.physician .sectionContainer .registration .heading-01 {
  text-transform: uppercase;
  font-size: 1rem;
  color: #082F58;
  font-weight: 600;
}
.physician .sectionContainer .registration .heading-02 {
  font-size: 1rem;
  font-weight: 500;
}
.physician .sectionContainer .registration .get_start a {
  display: inline-block;
  padding: 0.25rem 0;
  color: #0A670A;
  font-size: 1rem;
  font-weight: 500;
}

.physicianTemplate {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
.physicianTemplate img.backgroundSource {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.physicianTemplate .sectionContainer {
  position: relative;
  z-index: 200;
}
.physicianTemplate .sectionContainer .section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.physicianTemplate .sectionContainer .section .sectionDetail {
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}
.physicianTemplate .sectionContainer .section .sectionDetail .actionbutton {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 1rem;
}
.physicianTemplate .sectionContainer .section .sectionDetail .actionbutton a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #082F58;
  color: #fff;
  border-radius: 1rem;
  text-decoration: none;
}
.physicianTemplate .sectionContainer .section .sectionDetail .actionbutton a i.fa {
  margin-right: 0.25rem;
}
.physicianTemplate .sectionContainer .section .sectionDetail .actionbutton a:hover {
  background: #0A670A;
}
.physicianTemplate .sectionContainer .section.optionA {
  background: #d7d7e6;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail {
  position: relative;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .heading-01 {
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #082F58;
  font-weight: 600;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .heading-02 {
  font-size: 1.25rem;
  font-weight: 500;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .text-01 {
  font-style: italic;
  margin-bottom: 0.25rem;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .text-01 strong {
  font-size: 1rem;
  font-weight: 500;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .text-01 + p {
  margin-bottom: 1rem;
}
.physicianTemplate .sectionContainer .section.optionA .sectionDetail .text-01 + p strong {
  display: block;
}
.physicianTemplate .sectionContainer .section.optionB {
  background: #d7d7e6;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail {
  position: relative;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail .heading-01 {
  text-transform: uppercase;
  font-size: 1.25rem;
  color: #082F58;
  font-weight: 600;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail .text-01 {
  font-style: italic;
  margin-bottom: 1rem;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail .text-01 strong {
  font-size: 1rem;
  font-weight: 500;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail .text-01 + p {
  margin-bottom: 1rem;
}
.physicianTemplate .sectionContainer .section.optionB .sectionDetail .text-01 + p strong {
  display: block;
}
@media only screen and (max-width: 767px) {
  .physicianTemplate .sectionContainer {
    padding: 1.25rem 0 !important;
    padding-bottom: 0 !important;
  }
  .physicianTemplate .sectionContainer .section {
    display: flex;
    flex-direction: column;
  }
  .physicianTemplate .sectionContainer .section .sectionDetail {
    padding: 1rem !important;
  }
  .physicianTemplate .sectionContainer .section.optionB {
    position: relative;
  }
  .physicianTemplate .sectionContainer .section.optionB .sectionDetail {
    order: 2;
  }
}

.promosTemplate {
  position: relative;
  display: flex;
  flex-direction: column;
}
.promosTemplate .sectionContainer {
  position: relative;
  z-index: 200;
}
.promosTemplate .sectionContainer .promoSection {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  row-gap: 0.5rem;
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.promosTemplate .sectionContainer .promoSection:nth-of-type(even) {
  background: #f1f1f1;
}
.promosTemplate .sectionContainer .promoSection .item {
  position: relative;
  padding: 0 1rem;
}
.promosTemplate .sectionContainer .promoSection .item ul {
  list-style-type: none;
  margin-bottom: 0;
}
.promosTemplate .sectionContainer .promoSection .item p {
  margin-bottom: 0;
}
.promosTemplate .sectionContainer .promoSection .item.status {
  position: relative;
}
.promosTemplate .sectionContainer .promoSection .item.status small {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  position: relative;
  padding-left: 1.75rem;
}
.promosTemplate .sectionContainer .promoSection .item.status small img {
  position: absolute;
  left: 1px;
  top: 1px;
  width: 24px;
}
.promosTemplate .sectionContainer .promoSection .item.status small.active {
  background: #339900;
  color: #fff;
}
.promosTemplate .sectionContainer .promoSection .item.heading {
  position: relative;
}
.promosTemplate .sectionContainer .promoSection .item.heading h4 {
  margin-bottom: 0.25rem;
  color: #0A670A;
  text-transform: uppercase;
  font-size: 1rem;
}
.promosTemplate .sectionContainer .promoSection .item.heading h4 + p {
  font-style: italic;
}
.promosTemplate .sectionContainer .promoSection .item.heading h4 + p + strong {
  position: absolute;
  right: 1rem;
  top: 0;
  font-size: 1rem;
}
.promosTemplate .sectionContainer .promoSection .item.heading h4 + p + strong span {
  text-decoration: line-through;
  color: #cc3300;
}
.promosTemplate .sectionContainer .promoSection .item.condition {
  position: relative;
}
.promosTemplate .sectionContainer .promoSection .item.condition ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.promosTemplate .sectionContainer .promoSection .item.condition ul li {
  position: relative;
}
.promosTemplate .sectionContainer .promoSection .item.condition ul li:before {
  content: "\f105";
  font-family: "FontAwesome";
  display: inline-block;
  margin-right: 0.5rem;
  color: #082F58;
}

.explore {
  display: flex;
  flex-direction: column;
}
.explore a.highlight {
  text-decoration: underline;
  color: #dd3333;
}
.explore .page_header {
  position: relative;
  border-bottom: 1px solid #dddcdb;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.explore .page_header h5 {
  color: #0A670A;
  margin-bottom: 0.25rem;
}
.explore .page_header h5 + strong {
  color: #082F58;
}
.explore .page_body {
  position: relative;
}
.explore .page_body h6.subtitle {
  color: #082F58;
  margin-bottom: 0.75rem;
}
.explore .page_body ul {
  list-style-type: none;
  list-style-position: inside;
}
.explore .page_body ul li {
  padding-left: 0.75rem;
  line-height: 1.75;
  position: relative;
}
.explore .page_body ul li:before {
  content: "\f105";
  position: absolute;
  font-family: "FontAwesome";
  left: 0;
  color: #0A670A;
}
.explore .page_body .graphic {
  width: 100%;
  text-align: center;
  padding: 2rem;
}
.explore .page_body .graphic img {
  width: 100%;
  border-radius: 1rem;
  border: 1px solid #dddcdb;
}
.explore .page_body .profile {
  display: grid;
  grid-template-columns: max-content auto;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.explore .page_body .profile .picture img {
  border-radius: 1rem;
  border: 1px solid #dddcdb;
}
.explore .FaqListing {
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: column;
}
.explore .FaqListing .heading {
  background: #f6f6f6;
  border-bottom: 1px solid #dddcdb;
  padding: 0.75rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.explore .FaqListing .heading h5 {
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #082F58;
}
.explore .FaqListing .faqs {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.75rem 1rem;
}
.explore .FaqListing .faqs:before {
  content: "";
  width: 100px;
  height: 0.5px;
  position: absolute;
  left: 1rem;
  bottom: 0;
  background: #0A670A;
}
.explore .FaqListing .faqs:last-of-type {
  position: relative;
}
.explore .FaqListing .faqs:last-of-type:before {
  display: none;
}
.explore .FaqListing .faqs h6 {
  color: #0A670A;
  margin-bottom: 0;
  font-weight: 500;
}
.explore .FaqListing .faqs .answer {
  width: 100%;
  position: relative;
  padding-top: 0.25rem;
}
.explore .FaqListing .faqs .answer p {
  margin-bottom: 1rem;
}
.explore .FaqListing .faqs .answer p:last-of-type {
  margin-bottom: 0;
}
.explore .FaqListing .faqs .answer a {
  color: red;
  text-decoration: underline;
}
.explore .page_footer {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border: 1px solid #dddcdb;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: #f6f6f6;
}
.explore .page_footer .switch_faq {
  position: relative;
}
.explore .page_footer .switch_faq a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #0A670A;
  color: #fff;
  border-radius: 2rem;
  font-size: 0.75rem;
}
.explore .page_footer .switch_faq a i.fa {
  margin-right: 0.25rem;
}
.explore .page_footer .switch_contact {
  font-style: italic;
}
.explore .page_footer .switch_contact a {
  color: #0A670A;
  text-decoration: underline;
}

.contact {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 1rem 0;
}
.contact .pageHeader {
  position: relative;
}
.contact .pageHeader:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #0A670A;
  position: absolute;
  left: 0rem;
  bottom: 0;
}
.contact .pageHeader h4 {
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 1.25rem;
  color: #000000;
}
.contact .content_section {
  display: grid;
  grid-template-columns: 650px auto;
  -moz-column-gap: 5rem;
       column-gap: 5rem;
}
.contact .content_section .contact_type {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.contact .content_section .contact_type a {
  color: #0A670A;
}
.contact .content_section .contact_type .item_box {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #0A670A;
}
.contact .content_section .contact_type .item_box small {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}
.contact .content_section .contact_type .item_box ul {
  list-style-type: none;
  font-weight: 400;
}
.contact .content_section .contact_type .item_box ul li {
  padding: 2px 0;
}
.contact .content_section .contact_type .item_box ul li a {
  color: #0A670A;
}
@media only screen and (max-width: 767px) {
  .contact {
    padding: 0rem;
  }
  .contact .content_section {
    display: flex;
    flex-direction: column;
  }
}

#cannabis-education {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
#cannabis-education .introduction {
  padding-bottom: 1rem;
}
#cannabis-education .introduction a {
  color: red;
  text-decoration: underline;
}
#cannabis-education .introduction h2 {
  color: #0A670A;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
}
#cannabis-education .introduction h2 + p {
  margin-bottom: 0;
}
#cannabis-education .introduction h2 + p strong {
  font-weight: 600;
  color: #082F58;
}
#cannabis-education .introduction h2 + p + .content {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}
#cannabis-education .introduction h2 + p + .content p {
  margin-bottom: 0.5rem;
}
#cannabis-education .introduction h2 + p + .content p:last-of-type {
  margin-bottom: 0;
}
#cannabis-education .topic_list {
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
}
#cannabis-education .topic_list ul {
  list-style-type: none;
  margin: 0;
}
#cannabis-education .topic_list ul li {
  border-bottom: 1px solid #dddcdb;
  font-size: 1rem;
  font-weight: 500;
  color: #0A670A;
}
#cannabis-education .topic_list ul li:last-of-type {
  border-bottom: none;
}
#cannabis-education .topic_list ul li:nth-of-type(even) {
  background: #f6f6f6;
}
#cannabis-education .topic_list ul li a {
  display: block;
  padding: 0.5rem 0.75rem;
  color: #0A670A;
  text-decoration: none;
}
#cannabis-education .topic_list ul li a:hover {
  padding-left: 1rem;
}
#cannabis-education .melissamashupVideo {
  display: grid;
  grid-template-columns: 350px auto;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
#cannabis-education .melissamashupVideo video {
  width: 100% !important;
  height: auto;
}
@media only screen and (max-width: 767px) {
  #cannabis-education .melissamashupVideo {
    display: flex;
    flex-direction: column;
  }
}

.topics {
  display: grid;
  grid-template-columns: 250px auto;
  align-items: flex-start;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.topics .category {
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
}
.topics .category ul {
  list-style-type: none;
  margin-bottom: 0;
}
.topics .category ul li {
  position: relative;
}
.topics .category ul li a {
  display: block;
  border-bottom: 1px solid #dddcdb;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  font-weight: 500;
  background: #fff;
}
.topics .category ul li a.active, .topics .category ul li a:hover {
  background: #0A670A;
  color: #fff;
}
.topics .category ul li:last-of-type a {
  border-bottom-left-radius: 0.25rem;
  border-bottom: none;
}
.topics .category ul li:first-of-type a {
  border-top-left-radius: 0.25rem;
}
.topics .pagedata {
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
}
.topics .pagedata .disclaimer {
  padding: 0.5rem 1rem;
  font-style: italic;
}
.topics .pagedata .disclaimer a {
  color: red;
  text-decoration: underline;
}
.topics .customer_review {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.5rem 1rem;
  background: #fff;
  border-bottom: 1px solid #dddcdb;
}
.topics .customer_review:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.topics .customer_review:last-of-type {
  border-bottom: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.topics .customer_review:nth-of-type(even) {
  background: #f6f6f6;
}
.topics .customer_review h6 {
  margin-bottom: 0.25rem;
  color: #0A670A;
}
.topics .customer_review h6 a {
  color: #0A670A;
}
.topics .customer_review h6 + p {
  margin-bottom: 0.25rem;
}
.topics .customer_review .source a {
  display: inline-block;
  color: #0A670A;
  padding: 1px 0.5rem;
  background: #c1c1c1;
  border-radius: 2rem;
  font-size: 0.75rem;
  text-decoration: none;
}
.topics .customer_review .source a:hover {
  background: #0A670A;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .topics {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0;
  }
  .topics .category {
    border-right: 1px solid #dddcdb;
    border-radius: 0.25rem !important;
    width: 100%;
  }
  .topics .category ul li {
    position: relative;
  }
  .topics .category ul li:last-of-type a {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .topics .category ul li:first-of-type a {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .topics .FaqListing {
    border-top-left-radius: 0.25rem;
  }
}

.searchTemplate {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}
.searchTemplate .dataForm .data_row {
  display: grid;
  grid-template-columns: max-content auto 100px;
  align-items: center;
  -moz-column-gap: 0rem;
       column-gap: 0rem;
  margin-bottom: 2rem;
}
.searchTemplate .dataForm .data_row .label {
  background: #f1f1f1;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 1px solid #dddcdb;
  border-right: none;
  padding: 0.5rem 0.75rem;
}
.searchTemplate .dataForm .data_row .label i.fa {
  color: #0A670A;
  margin-right: 0.25rem;
}
.searchTemplate .dataForm .data_row .inputField {
  margin-bottom: 0;
}
.searchTemplate .dataForm .data_row .inputField input[type=text] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.searchTemplate .dataForm .data_row .inputField.button {
  padding-left: 1rem;
}
.searchTemplate .searchResult {
  display: flex;
  flex-direction: column;
}
.searchTemplate .searchResult .loadingContainer {
  text-align: center;
}
.searchTemplate .searchResult .loadingContainer img {
  width: 200px;
}
.searchTemplate .searchResult .error {
  color: #cc3300;
  font-size: 0.75rem;
}
.searchTemplate .searchResult .fetchData {
  display: flex;
  flex-direction: column;
}
.searchTemplate .searchResult .fetchData .countRecord {
  font-weight: 500;
  color: #082F58;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
}
.searchTemplate .searchResult .fetchData .fetchRows {
  display: block;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  padding: 0.5rem 0.75rem;
  background: #fff;
  margin-bottom: 0.75rem;
}
.searchTemplate .searchResult .fetchData .fetchRows:nth-of-type(odd) {
  background: #f1f1f1;
}
.searchTemplate .searchResult .fetchData .fetchRows h6 {
  color: #0A670A;
  margin-bottom: 0.25rem;
  font-weight: 500;
  cursor: pointer;
}
.searchTemplate .searchResult .fetchData .fetchRows h6 + p {
  margin-bottom: 0;
}

.profilDetail {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
}
.profilDetail .headerSection {
  display: grid;
  grid-template-columns: 200px auto;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  align-items: center;
  padding: 1rem 0;
}
.profilDetail .headerSection .profileImage {
  position: relative;
  padding: 5px;
}
.profilDetail .headerSection .profileImage img {
  border-radius: 100%;
}
.profilDetail .headerSection .profileImage .overlayer {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid #f6f6f6;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}
.profilDetail .headerSection .intro {
  position: relative;
}
.profilDetail .headerSection .intro h5 {
  text-transform: uppercase;
  color: #0A670A;
  margin-bottom: 0;
}
.profilDetail .headerSection .intro h5 small {
  display: block;
  font-size: 0.75rem;
  color: #000000;
  padding-top: 0.25rem;
  margin-bottom: 1rem;
}

.registration {
  display: flex;
  flex-direction: column;
}
.registration .alertMessage {
  display: flex;
  flex-direction: column;
}
.registration .pageTitle {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.registration .pageTitle h4 {
  margin-bottom: 0;
}
.registration .pageTitle h4 small {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
}
.registration .annualMembership {
  position: relative;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  padding: 1rem;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registration .annualMembership .item {
  position: relative;
}
.registration .annualMembership .item h6 {
  margin-bottom: 0.25rem;
}
.registration .annualMembership .item h6 + span {
  display: block;
  padding-bottom: 0.75rem;
}
.registration .annualMembership strong {
  font-size: 1.5rem;
  color: #0A670A;
}
.registration .authorization {
  margin-top: 1rem;
  position: relative;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  padding: 1rem;
  background: #f1f1f1;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.registration .authorization h6 {
  margin-bottom: 0.25rem;
  color: #0A670A;
}
.registration .authorization h6 + p {
  margin-bottom: 0.75rem;
}
.registration .authorization h6 + p a {
  color: red;
  text-decoration: underline;
}
.registration .authorization .police_accept {
  font-size: 0.75rem;
}
.registration .authorization .police_accept ul {
  list-style-type: none;
  margin: 0;
}
.registration .authorization .police_accept ul li {
  position: relative;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
}
.registration .authorization .police_accept ul li .erroralert {
  color: #cc3300;
}
.registration .authorization .police_accept ul li:last-of-type {
  padding-bottom: 0;
}
.registration .authorization .police_accept ul li img {
  width: 16px;
  position: absolute;
  left: 0;
}
.registration .authorization .police_accept ul li input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 5px;
}
.registration .authorization .police_accept ul li a {
  color: red;
  text-decoration: underline;
}
.registration .instruction {
  position: relative;
  position: relative;
  border-radius: 0.25rem;
  border: 1px solid #dddcdb;
  padding: 1rem;
  background: #fff;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 1rem;
}
.registration .instruction ul {
  list-style-type: none;
  margin-bottom: 0;
}
.registration .instruction ul li {
  padding-left: 0.75rem;
  line-height: 1.75;
  position: relative;
}
.registration .instruction ul li a {
  color: red;
  text-decoration: underline;
}
.registration .instruction ul li:before {
  content: "\f105";
  position: absolute;
  font-family: "FontAwesome";
  left: 0;
  color: #0A670A;
}
.registration .instruction ul li strong {
  font-weight: 500;
}

.newsletterSubscription {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}
.newsletterSubscription .pageTitle {
  position: relative;
  margin-bottom: 1rem;
  text-align: center;
}
.newsletterSubscription .pageTitle h4 {
  margin-bottom: 0;
}
.newsletterSubscription .pageTitle h4 small {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
}
.newsletterSubscription .dataForm {
  padding: 1rem 5rem;
}
@media only screen and (max-width: 767px) {
  .newsletterSubscription {
    padding: 0rem;
  }
  .newsletterSubscription h4 {
    font-size: 1rem;
  }
  .newsletterSubscription .dataForm {
    padding: 0rem;
  }
}

.enrollmentPlans {
  position: relative;
  padding: 3rem 0 !important;
}
.enrollmentPlans img.backgroundSource {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  -o-object-fit: contain;
     object-fit: contain;
}
.enrollmentPlans .sectionContainer {
  position: relative;
  z-index: 200;
  display: flex;
  justify-content: center;
}
.enrollmentPlans .sectionContainer .plansWrap {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
.enrollmentPlans .sectionContainer .plansWrap .heading {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 1rem;
  color: #082F58;
  display: none;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader {
  display: grid;
  grid-template-columns: 300px repeat(2, 1fr);
  text-align: center;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item {
  background: #f5f5f5;
  padding: 1rem 0;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item:first-of-type {
  background: transparent;
  border-top: none;
  position: relative;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item:first-of-type img {
  width: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item.basic {
  border-top-left-radius: 0.25rem;
  border-right: 1px solid #dddcdb;
  border-top: 1px solid #dddcdb;
  border-left: 1px solid #dddcdb;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item.premimum {
  border-top-right-radius: 0.25rem;
  border-right: 1px solid #dddcdb;
  border-top: 1px solid #dddcdb;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .membershipShild {
  text-align: center;
  margin-bottom: 0.25rem;
  display: none;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan small.discount {
  display: block;
  font-size: 0.75rem;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan small.discount span {
  text-decoration: line-through;
  color: #082F58;
  font-weight: 400;
  color: #cc3300;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong {
  font-size: 1rem;
  font-weight: 600;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong + span.price {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong + span.price strong {
  text-decoration: line-through;
  color: #cc3300;
  font-weight: 600;
  font-size: 1.75rem;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong + span.price + span.text {
  margin-bottom: 0rem;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong + span.price + span.text + small.text01 {
  margin-bottom: 0.5rem;
  color: #082F58;
  font-style: italic;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan a.enroll_now {
  font-size: 0.75rem;
  display: inline-block;
  background: #0A670A;
  color: #fff;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  text-decoration: none;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan a.enroll_now:hover {
  background: #085008;
}
.enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul {
  display: none;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer {
  background: #f5f5f5;
  border: 1px solid #dddcdb;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section {
  position: relative;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap {
  background: #fff;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom: 1px solid #dddcdb;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features {
  display: grid;
  grid-template-columns: 300px repeat(2, 1fr);
  align-items: center;
  border-bottom: 1px solid #dddcdb;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features:last-of-type {
  border-bottom: none;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features .item {
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem;
  text-align: center;
  border-right: 1px solid #dddcdb;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features .item.title {
  text-align: left;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features .item.title span {
  color: #dd3333;
  font-style: italic;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.featuresWrap .features .item img {
  width: 16px;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.include {
  background: #f5f5f5;
  padding: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.include h6 {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.enrollmentPlans .sectionContainer .plansWrap .bodyContainer .section.include h6 + ul {
  list-style-type: decimal;
  margin-bottom: 0;
  font-size: 0.75rem;
}
.enrollmentPlans .sectionContainer .plansWrap .singIn {
  text-align: center;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 767px) {
    margin-left: 300px;
  }
}
.enrollmentPlans .sectionContainer .plansWrap .singIn a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: #0A670A;
  color: #fff;
  border-radius: 1rem;
  text-decoration: none;
}
.enrollmentPlans .sectionContainer .plansWrap .singIn a:hover {
  background: #085008;
}
@media only screen and (max-width: 767px) {
  .enrollmentPlans .sectionContainer .plansWrap {
    width: 100%;
  }
  .enrollmentPlans .sectionContainer .plansWrap .heading {
    display: block;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddcdb;
    background: #f5f5f5;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item {
    background: #f5f5f5;
    padding: 1rem 0;
    border: none !important;
    border-radius: 0 !important;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item:last-of-type {
    background: #fff;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item:first-of-type {
    position: relative;
    display: none;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item:first-of-type img {
    position: static;
    transform: unset;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .membershipShild {
    display: block;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan {
    position: relative;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan strong {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 0.5;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan a {
    font-size: 0.875rem;
    display: inline-block;
    background: #0A670A;
    color: #fff;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    text-decoration: none;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul {
    display: block;
    list-style-type: none;
    text-align: left;
    padding: 0 1rem;
    border-top: 1px solid #dddcdb;
    margin-top: 1rem;
    padding-top: 1rem;
    margin-bottom: 0;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul li {
    padding-left: 0.75rem;
    line-height: 1.75;
    position: relative;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul li:before {
    content: "\f105";
    position: absolute;
    font-family: "FontAwesome";
    left: 0;
    color: #0A670A;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul li.heading {
    font-weight: 500;
    padding-bottom: 0.25rem;
    text-transform: uppercase;
    color: #0A670A;
    padding-left: 0rem;
  }
  .enrollmentPlans .sectionContainer .plansWrap .planHeader .item .plan + ul li.heading:before {
    display: none;
  }
  .enrollmentPlans .sectionContainer .plansWrap .bodyContainer {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .enrollmentPlans {
    padding: 1rem !important;
    margin-top: 1rem;
  }
  .enrollmentPlans .sectionContainer {
    padding: 0 !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.templateBody .sourceTemplate {
  display: flex;
  flex-direction: column;
}
.templateBody .sourceTemplate .sectionWrap {
  padding: 1rem 0;
}
.templateBody .sourceTemplate .showError {
  text-align: center;
  padding: 2rem 0;
}
.templateBody .sourceTemplate .showError span {
  display: inline-block;
  color: #cc3300;
  padding: 0.25rem 0.75rem;
  text-transform: capitalize;
}
.templateBody .sourceTemplate .showLoading {
  text-align: center;
  padding: 2rem 0;
}
.templateBody .sourceTemplate .showLoading img {
  width: 250px;
}

:root {
  /* COLORS */
  --cookie-consent-banner-colors-primary: #81c784;
  --cookie-consent-banner-colors-primary-border: #81c784;
  --cookie-consent-banner-colors-primary-content: #fff;
  --cookie-consent-banner-colors-secondary: transparent;
  --cookie-consent-banner-colors-secondary-border: #fff;
  --cookie-consent-banner-colors-secondary-content: #fff;
  --cookie-consent-banner-colors-background-body: rgba(25, 31, 34, 0.92);
  --cookie-consent-banner-colors-text: #fff;
  /* BORDER-RADIUS */
  --cookie-consent-banner-border-radius-buttons: 3rem;
  --cookie-consent-banner-border-radius-body: 0;
  /* SPACINGS */
  --cookie-consent-banner-spacings-container-padding-top: 0rem;
  --cookie-consent-banner-spacings-container-padding-left: 0rem;
  --cookie-consent-banner-spacings-container-padding-bottom: 0rem;
  --cookie-consent-banner-spacings-container-padding-right: 0rem;
  --cookie-consent-banner-spacings-body-padding-top: 0.25rem;
  --cookie-consent-banner-spacings-body-padding-left: 2rem;
  --cookie-consent-banner-spacings-body-padding-bottom: 1rem;
  --cookie-consent-banner-spacings-body-padding-right: 2rem;
  /* Z-INDEX */
  --cookie-consent-banner-z-index-container: 2000;
  /* FONTS */
  --cookie-consent-banner-font-family-headline: inherit;
  --cookie-consent-banner-font-size-headline: 1.5rem;
  --cookie-consent-banner-font-family-body: inherit;
  --cookie-consent-banner-font-size-body: 0.875rem;
}/*# sourceMappingURL=style.css.map */
