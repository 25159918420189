.accordion {
  display: flex;
  flex-direction: column; 
  .accordion-item {
    display: flex;
    flex-direction: column;
    .accordion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: $border;
      padding: 0.5rem 0;
      font-weight: 500;
      color: $text-secondary;
      font-size: 1rem;
      cursor: pointer; 
    }
    .accordion-content {
      position: relative;
      a {
        text-decoration: underline;
      }
      ul {
        list-style-type: none;
        li {
          padding-left: 1rem;
          position: relative;
          &:before {
            content: "\f105"; 
            font-family: "FontAwesome";
            position: absolute;
            left: 0;
          }
        }
        &#dosingwork {
          position: relative;
          li {
            padding-left: 0;
            display: grid;
            grid-template-columns: 50px auto;
            padding-bottom: 2px;
            &:before {
              display: none;
            }
            span {
              font-weight: 500;
            }
          }
        }
      }
      // STATETABLE
      .stateTable {
        display: flex;
        flex-direction: column;
        strong {
          font-weight: 500;
          margin-bottom: 1rem;
        }
        .tablerow {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          border: $border; 
          ul {
            list-style-type: none;
            margin-bottom: 0;
            li {
              position: relative;
              border-bottom: $border;
              padding: 0.25rem 0.75rem;
              &:last-of-type {
                border-bottom: none;
              }
              &:before {
                display: none;
              }
            }
          } 
          & + span {
            display: block;
            margin-bottom: 1rem;
            padding-top: 1rem;
            font-weight: 500;
          }
        }
      }
      // stateListing
      .stateListing {
        display: flex;
        flex-direction: column;
        strong {
          font-weight: 500;
          margin-bottom: 1rem;
        }
        .tablerow {
          display: grid;
          grid-template-columns: 200px auto;
          border: $border; 
          &:last-of-type {
            border-bottom: navy;
          }
          &.header {
            border-bottom: none;
            font-weight: 600;
            background: #f6f6f6;
            span {
              display: block;
              padding: 0.5rem 0.75rem;
            }
          }
          .tableColumn {
            position: relative;
            ul {
              list-style-type: none;
              margin-bottom: 0;
              li {
                position: relative;
                border-bottom: $border;
                padding: 0.25rem 0.75rem;
                &:last-of-type {
                  border-bottom: none;
                }
                &:before {
                  display: none;
                }
              } 
            }
            &:first-of-type {
              font-weight: 500;
              background: #f1f1f1;
              border-right: $border;
            }
          }
         
        }
      }
    }
    &:first-of-type {
      position: relative;
      .accordion-title {
        border-top: none;
      }
    }
  }
}
