.referralmodal {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: transparentize($color: #000000, $amount: 0.2);
  .referral_container {
    width: 90%;
    max-width: 700px;
    background: #fff;
    border-radius: 1rem;
    margin-bottom: 2rem;
    border: $border;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    a.close {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      font-size: 1.25rem;
    }
    h4 {
      margin-bottom: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $text-secondary;
      & + p {
        margin-bottom: 0.75rem;
        & + ul {
          list-style-type: none;
        }
      }
    }
    .read_more {
      padding: 1.25rem;
      a {
        display: inline-block;
        background: $bg-secondary;
        color: #fff;
        padding: 0.5rem 1.25rem;
        border-radius: 2rem;
      }
      & + .highlight_logo {
        margin-bottom: 0rem;
        img {
          max-width: 300px;
        }
      }
    }
  }
}