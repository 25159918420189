//FAQS
.faqsWrap {
 @extend .innerContainer;
 display: grid;
 grid-template-columns: 250px auto;
 align-items: flex-start;
 .faqs_categories {
  border: $border;
  border-right: 0;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  ul {
   list-style-type: none;
   margin-bottom: 0;
   li {
    position: relative;
    a {
     display: block;
     border-bottom: $border;
     padding: 0.5rem 0.75rem;
     text-decoration: none;
     font-weight: 500;
     background: #fff;
     &.active,
     &:hover {
      background: $bg-secondary;
      color: #fff;
     }
    }
    &:last-of-type a {
     border-bottom-left-radius: $radius;
     border-bottom: none;
    }
    &:first-of-type a {
     border-top-left-radius: $radius;
    }
   }
  }
 }
 .FaqListing {
  border-radius: $radius;
  border: $border;
  padding: 0.5rem 1rem;
  border-top-left-radius: 0;
 }
 .faqs {
  position: relative;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 1rem;
  &:before {
   content: '';
   width: 100px;
   height: 0.5px;
   position: absolute;
   left: 0;
   bottom: 0;
   background: $bg-secondary;
  }
  &:last-of-type {
   padding-bottom: 0;
   &:before {
    display: none;
   }
  }
  h6 {
   color: $text-secondary;
   margin-bottom: 0;
   font-weight: 500;
   cursor: pointer;
  }
  .answer {
   width: 100%;
   position: relative;
   padding-top: 0.25rem;
   p {
    margin-bottom: 1rem;
    &:last-of-type {
     margin-bottom: 0;
    }
   }
   a {
    color: red;
    text-decoration: underline;
   }
  }
 }
 @include media-max(767px) {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0;
  .faqs_categories {
   border-right: $border;
   border-radius: $radius !important;
   width: 100%;
   ul li {
    position: relative;
    &:last-of-type a {
     border-bottom-left-radius: $radius;
     border-bottom-right-radius: $radius;
    }
    &:first-of-type a {
     border-top-left-radius: $radius;
     border-top-right-radius: $radius;
    }
   }
  }
  .FaqListing {
   border-top-left-radius: $radius;
  }
 }
}

// TESTIMONIALWRAP
.testimonialWrap {
 @extend .innerContainer;
 display: flex;
 flex-direction: column;
 row-gap: 2.5rem;
 padding: 1rem 0;
 position: relative;
 .item {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  font-style: italic;
  .signature {
   color: $text-primary;
   font-style: normal;
  }
 }
}

// COMPANY_PROFILE
.company_profile {
 position: relative;
 display: flex;
 flex-direction: column;
 blockquote {
  border: 1px solid #b0d2cb;
  background: #f8fffe;
  padding: 2rem;
  color: #0b4237;
  font-family: 'Gelasio', serif;
  margin: 1rem 0 2rem;
  &.bordered {
   border-left: 10px solid #b0d2cb;
  }
  &:before {
   content: '\201c';
   font-size: 4rem;
   color: #0b4237;
   display: block;
   margin-bottom: -50px;
   margin-top: -20px;
  }
  .blockquote_text {
   font-style: italic;
   font-size: 1rem;
   line-height: 1.5rem;
   margin: 0;
  }
  .blockquote_credit {
   text-align: right;
   margin: 0;
   font-weight: 800;
   &:before {
    font-weight: 800;
    content: '\2014\0020';
   }
   small {
    display: block;
    font-weight: 400;
   }
  }
 }
}

// MEMBER_BENEFITS
.member_benefits {
 display: flex;
 flex-direction: column;
 .membership_includes {
  position: relative;
  a.switch_signup {
   color: red;
  }
  h6 {
   margin-bottom: 0.75rem;

   color: $text-secondary;
   a {
    color: $text-secondary;
    text-decoration: underline;
    &:hover {
     text-decoration: none;
    }
   }
   & + ul {
    list-style-type: none;
    list-style-position: inside;
    li {
     padding-left: 0.75rem;
     line-height: 1.75;
     position: relative;
     &:before {
      content: '\f105';
      position: absolute;
      font-family: 'FontAwesome';
      left: 0;
      color: $text-secondary;
     }
    }
   }
  }
 }
 blockquote {
  border: 1px solid #b0d2cb;
  background: #f8fffe;
  padding: 2rem;
  color: #0b4237;
  font-family: 'Gelasio', serif;
  margin: 1rem 0 2rem;
  &.bordered {
   border-left: 10px solid #b0d2cb;
  }
  &:before {
   content: '\201c';
   font-size: 4rem;
   color: #0b4237;
   display: block;
   margin-bottom: -50px;
   margin-top: -20px;
  }
  .blockquote_text {
   font-style: italic;
   font-size: 1rem;
   line-height: 1.5rem;
   margin: 0;
  }
  .blockquote_credit {
   text-align: right;
   margin: 0;
   font-weight: 800;
   &:before {
    font-weight: 800;
    content: '\2014\0020';
   }
   small {
    display: block;
    font-weight: 400;
   }
  }
 }

 .page_footer {
  border: $border;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  .switch_faq {
   position: relative;
   a {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: $bg-secondary;
    color: #fff;
    border-radius: 2rem;
    font-size: 0.75rem;
    i.fa {
     margin-right: 0.25rem;
    }
   }
  }
  .switch_contact {
   font-style: italic;
   a {
    color: $text-secondary;
    text-decoration: underline;
   }
  }
 }
}

// PAYMENTINSTRUCTION
.paymentInstruction {
 @extend .wrapContainer;
 padding: 2rem 0;
 a {
  color: red;
  text-decoration: none;
 }
 .alertSuccess {
  position: relative;
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 1rem;
  .alertText {
   position: relative;
   margin-bottom: 1rem;
   h5 {
    margin-bottom: 0.25rem;
    & + p {
     margin-bottom: 0.5rem;
     & + h4 {
      margin-bottom: 0;
      span {
       color: $text-green;
       font-weight: 600;
      }
     }
    }
   }
  }
 }
 .title {
  position: relative;
  margin-bottom: 0.75rem;
  h5 {
   margin-bottom: 0.25rem;
   span {
    display: block;
    font-size: 0.875rem;
    padding-top: 0.25rem;
   }
   & + p {
    font-style: italic;
   }
  }
 }
 .instructioncontainer {
  @extend .innerContainer;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  .paymentmode {
   display: flex;
   flex-direction: column;
   border: $border;
   padding: 1rem;
   border-radius: $radius;
   margin-bottom: 1rem;
   h6 {
    margin-bottom: 1rem;
    color: $text-primary;
    font-weight: 600;
   }
   .payment {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    &:last-of-type {
     margin-bottom: 0;
    }
    ul {
     list-style-type: none;
     margin-bottom: 0;
    }
   }
  }
 }
}

// PRESSRELEASES
.pressReleases {
 @extend .wrapContainer;
 padding: 2rem 0;
 .container {
  @extend .innerContainer;
  display: grid;
  grid-template-columns: 350px auto;
  column-gap: 1rem;
  a {
   color: red;
   text-decoration: underline;
  }
  .graphic {
   position: relative;
   img {
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
   }
  }
  @include media-max(767px) {
   display: flex;
   flex-direction: column;
   .graphic {
    margin-bottom: 1rem;
    img {
     border-radius: 0 !important;
    }
   }
  }
 }
}

// UNDERCONSTRUCTION
.underConstruction {
 @extend .wrapContainer;
 padding: 2rem;
 text-align: center;
 img {
  max-width: 300px;
 }
}

// AFFILIATES
.affiliates {
 position: relative;
 display: flex;
 flex-direction: column;
 padding: 0 !important;
 img.backgroundSource {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;
  object-fit: cover;
  height: 100%;
 }
 .sectionContainer {
  position: relative;
  z-index: 200;
  .section {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   align-items: center;
   .sectionDetail {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    .actionbutton {
     display: flex;
     column-gap: 1rem;
     margin-bottom: 1rem;
     a {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      background: $bg-primary;
      color: #fff;
      border-radius: 1rem;
      text-decoration: none;
      i.fa {
       margin-right: 0.25rem;
      }
      &:hover {
       background: $bg-secondary;
      }
     }
    }
   }
   &.ardent {
    background: #d7d7e6;
    .sectionDetail {
     position: relative;
     .discount {
      text-transform: uppercase;
      font-size: 1.25rem;
      color: $text-primary;
      font-weight: 600;
     }
     .heading {
      font-size: 1.25rem;
      font-weight: 500;
     }
     .code {
      font-style: italic;
      margin-bottom: 1rem;
      strong {
       font-size: 1rem;
       font-weight: 500;
      }
      & + p {
       margin-bottom: 1rem;
       strong {
        display: block;
       }
      }
     }
    }
   }
   &.nuggmd {
    background: #d7d7e6;
    .sectionDetail {
     position: relative;
     .heading-01 {
      text-transform: uppercase;
      font-size: 1.25rem;
      color: $text-primary;
      font-weight: 600;
     }
     .heading-02 {
      font-size: 1.25rem;
      font-weight: 500;
     }
     .text-01 {
      font-style: italic;
      margin-bottom: 1rem;
      strong {
       font-size: 1rem;
       font-weight: 500;
      }
      & + p {
       margin-bottom: 1rem;
       strong {
        display: block;
       }
      }
     }
    }
   }
  }
  @include media-max(767px) {
   padding: 1.25rem 0 !important;
   padding-bottom: 0 !important;
   .section {
    display: flex;
    flex-direction: column;
    .sectionDetail {
     padding: 1rem !important;
    }
    &.nuggmd {
     position: relative;
     .sectionDetail {
      order: 2;
     }
    }
    &.ardent {
     position: relative;
     .sectionDetail {
      order: 2;
     }
    }
   }
  }
 }
}

// PHYSICIAN
.physician {
 position: relative;
 display: flex;
 flex-direction: column;
 padding: 0 !important;
 overflow: hidden;
 img.backgroundSource {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  object-fit: contain;
 }
 .sectionContainer {
  position: relative;
  z-index: 200;
  .section {
   display: flex;
   flex-direction: column;
   height: 500px;
   justify-content: center;
   padding-left: 1rem;
   .heading-01 {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: $text-primary;
    font-weight: 600;
   }
   .heading-02 {
    font-size: 1.25rem;
    font-weight: 500;
   }
   .text-01 {
    font-style: italic;
    margin-bottom: 0.25rem;
    strong {
     font-size: 1rem;
     font-weight: 500;
    }
    & + p {
     margin-bottom: 1rem;
     strong {
      display: block;
     }
    }
   }
   .actionbutton {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
    a {
     display: inline-block;
     padding: 0.25rem 0.75rem;
     background: $bg-primary;
     color: #fff;
     border-radius: 1rem;
     text-decoration: none;
     i.fa {
      margin-right: 0.25rem;
     }
     &:hover {
      background: $bg-secondary;
     }
    }
   }
  }
  .registration {
   margin-top: 2rem;
   .heading-01 {
    text-transform: uppercase;
    font-size: 1rem;
    color: $text-primary;
    font-weight: 600;
   }
   .heading-02 {
    font-size: 1rem;
    font-weight: 500;
   }
   .get_start a {
    display: inline-block;
    padding: 0.25rem 0;
    color: $text-secondary;
    font-size: 1rem;
    font-weight: 500;
   }
  }
 }
}

// PHYSICIANTEMPLATE
.physicianTemplate {
 position: relative;
 display: flex;
 flex-direction: column;
 padding: 0 !important;
 img.backgroundSource {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 100;
  object-fit: cover;
  height: 100%;
 }
 .sectionContainer {
  position: relative;
  z-index: 200;
  .section {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   align-items: center;
   .sectionDetail {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    .actionbutton {
     display: flex;
     column-gap: 1rem;
     margin-bottom: 1rem;
     a {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      background: $bg-primary;
      color: #fff;
      border-radius: 1rem;
      text-decoration: none;
      i.fa {
       margin-right: 0.25rem;
      }
      &:hover {
       background: $bg-secondary;
      }
     }
    }
   }
   &.optionA {
    background: #d7d7e6;
    .sectionDetail {
     position: relative;
     .heading-01 {
      text-transform: uppercase;
      font-size: 1.25rem;
      color: $text-primary;
      font-weight: 600;
     }
     .heading-02 {
      font-size: 1.25rem;
      font-weight: 500;
     }
     .text-01 {
      font-style: italic;
      margin-bottom: 0.25rem;
      strong {
       font-size: 1rem;
       font-weight: 500;
      }
      & + p {
       margin-bottom: 1rem;
       strong {
        display: block;
       }
      }
     }
    }
   }
   &.optionB {
    background: #d7d7e6;
    .sectionDetail {
     position: relative;
     .heading-01 {
      text-transform: uppercase;
      font-size: 1.25rem;
      color: $text-primary;
      font-weight: 600;
     }
     .text-01 {
      font-style: italic;
      margin-bottom: 1rem;
      strong {
       font-size: 1rem;
       font-weight: 500;
      }
      & + p {
       margin-bottom: 1rem;
       strong {
        display: block;
       }
      }
     }
    }
   }
  }
  @include media-max(767px) {
   padding: 1.25rem 0 !important;
   padding-bottom: 0 !important;
   .section {
    display: flex;
    flex-direction: column;
    .sectionDetail {
     padding: 1rem !important;
    }
    &.optionB {
     position: relative;
     .sectionDetail {
      order: 2;
     }
    }
   }
  }
 }
}

// PROMOS TEMPLATE
.promosTemplate {
 position: relative;
 display: flex;
 flex-direction: column;
 .sectionContainer {
  position: relative;
  z-index: 200;
  .promoSection {
   display: flex;
   flex-direction: column;
   border-radius: $radius;
   border: $border;
   row-gap: 0.5rem;
   padding: 1rem 0;
   margin-bottom: 1rem;
   &:nth-of-type(even) {
    background: #f1f1f1;
   }
   .item {
    position: relative;
    padding: 0 1rem;
    ul {
     list-style-type: none;
     margin-bottom: 0;
    }
    p {
     margin-bottom: 0;
    }
    &.status {
     position: relative;
     small {
      display: inline-block;
      padding: 0.25rem 0.75rem;
      border-radius: 2rem;
      position: relative;
      padding-left: 1.75rem;
      img {
       position: absolute;
       left: 1px;
       top: 1px;
       width: 24px;
      }
      &.active {
       background: $bg-success;
       color: #fff;
      }
     }
    }
    &.heading {
     position: relative;
     h4 {
      margin-bottom: 0.25rem;
      color: $text-green;
      text-transform: uppercase;
      font-size: 1rem;
      & + p {
       font-style: italic;
       & + strong {
        position: absolute;
        right: 1rem;
        top: 0;
        font-size: 1rem;
        span {
         text-decoration: line-through;
         color: $text-error;
        }
       }
      }
     }
    }
    &.condition {
     position: relative;
     ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
       position: relative;
       &:before {
        content: '\f105';
        font-family: 'FontAwesome';
        display: inline-block;
        margin-right: 0.5rem;
        color: $text-primary;
       }
      }
     }
    }
   }
  }
 }
}

// GET YOUR CARD
.stateListButton {
 padding: 0.5rem;
 .heading-01 {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.25rem;
  color: $text-primary;
  font-weight: 600;
  border-radius: 1rem;
  &:hover {
   background-color: $bg-gray;
   color: $text-primary;
   transition: 0.5s;
  }
 }
}

.stateListContent {
 display: flex;
 column-gap: 1rem;
 padding: 1rem;
 a {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: $bg-primary;
  color: #fff;
  border-radius: 1rem;
  text-decoration: none;
  i.fa {
   margin-right: 0.25rem;
  }
  &:hover {
   background: $bg-secondary;
  }
 }
 .heading-02 {
  font-size: 1.25rem;
  font-weight: 500;
 }
 .text-01 {
  font-style: italic;
  margin-bottom: 0.25rem;
  strong {
   font-size: 1rem;
   font-weight: 500;
  }
  & + p {
   margin-bottom: 1rem;
   strong {
    display: block;
   }
  }
 }
}
