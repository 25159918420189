//FONTS & SIZE
$bodyFont: "Roboto", sans-serif;
$bodyFontsize: 0.875rem;

//COLOR
$clr-primary: #082F58;
$clr-secondary: #0A670A;

//TEXT-COLOR
$text-body: #000000;
$text-primary: #082F58;
$text-secondary: #0A670A;
$text-green: #0A670A;
$text-green-light: #8BCE8B;

$text-success: #339900;
$text-error: #cc3300;
$text-warning: #26278b;

//BACKGROUND COLOR
$bg-primary: #082F58;
$bg-secondary: #0A670A;
$bg-green: #0A670A;
$bg-green-light: #8BCE8B;
$bg-gray: #ececec;

// BORDER
$border-primary: 1px solid #082F58;
$border-secondary: 1px solid #0A670A;

// ALERT BACKGROUND
$bg-success: #339900;
$bg-error: #cc3300;
$bg-warning: #26278b;

///TRANSITION
$transition: all ease 0.4s;

//TRANSFORM
$imgTransform: scale(1.1);

//RADIUS
$radius: 0.25rem;

//BORDER
$border: 1px solid #dddcdb;

//BREAK POINT
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
