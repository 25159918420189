.profilDetail {
  @extend .wrapContainer;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  .headerSection {
    display: grid;
    grid-template-columns: 200px auto;
    column-gap: 2rem;
    align-items: center;
    padding: 1rem 0;
    .profileImage {
      position: relative;
      padding: 5px;
      img {
        border-radius: 100%;
      }
      .overlayer {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 10px solid #f6f6f6;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 100;
      }
    }
    .intro {
      position: relative;
      h5 {
        text-transform: uppercase;
        color: $text-secondary;
        margin-bottom: 0;
        small {
          display: block;
          font-size: 0.75rem;
          color: $text-body;
          padding-top: 0.25rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}