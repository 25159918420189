.HamburgerMenu {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  background: transparentize($color: #000000, $amount: 0.2);
  .menuContainer {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    background: $bg-primary;
    padding: 1rem;
    .menuTitle {
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 0;
    }
    .navigation {
      height: 90vh;
      overflow-y: scroll;
      color: #fff;
      ul {
        list-style-type: none;
        li {
          position: relative;
          a {
            color: #fff;
            display: block;
            padding: 2px 0;
          }
          ul {
            margin-bottom: 0.5rem;
            li {
              padding-left: 0.5rem;
              a {
                font-weight: 400;
                font-size: 0.75rem;
                position: relative;
                padding: 1px 0;
                padding-left: 0.75rem;
                &:before {
                  content: "- ";
                  position: absolute;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
