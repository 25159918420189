.errorLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      margin-bottom: 0.25rem;
      & + p {
        margin-bottom: 1rem;
      }
    }
  }

}