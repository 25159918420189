.team_wrapper {
  display: flex;
  flex-direction: column;
  .section_title {
    position: relative;
    margin-bottom: 0.5rem;
    h3 {
      margin-bottom: 0;
      font-weight: 600;
      font-family: "Gelasio", serif;
      & + p {
        color: #0b4237;
      }
    }
  }

  .teamWrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: auto;
    grid-gap: 1.25rem;
    .team {
      position: relative;
      background: #fff;
      border: $border;
      border-radius: $radius;
      .imgBox {
        width: 100%;
        position: relative;
        overflow: hidden;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        padding: 1rem;
        img {
          width: 100%;
          transition: $transition;
          border-top-left-radius: $radius;
          border-top-right-radius: $radius;
          border-radius: 100%;
        }
         
      }
      .infoBox {
        padding: 0.5rem;
        position: relative;
        h6 {
          color: $text-primary;
          text-transform: uppercase;
          margin-bottom: 0.25rem;
          font-size: 0.875rem;
          & + span {
            display: block;
            font-size: 0.75rem;
          }
        }
        &:before {
          content: "";
          width: 0;
          height: 2px;
          background: $bg-primary;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          transition: $transition;
        }
      }
      &:hover {
        .infoBox:before {
          width: 100%;
        }
      }
    }
    @include media-max(992px) {
      grid-template-columns: repeat(3, 1fr);
      .team .infoBox::before {
        width: 70%;
      }
    }
    @include media-max(767px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      padding: 0;
      .team .infoBox {
        background: #f1f1f1;
        padding: 1rem;
        text-align: center;
        position: relative;
        span {
          padding-bottom: 0;
        }
      }
    }
  }
}
