.green-arrow-list {
  list-style-type: none;
  list-style-position: inside;
  li {
    padding-left: 0.75rem;
    line-height: 1.75;
    position: relative;
    &:before {
      content: '\f105';
      position: absolute;
      font-family: 'FontAwesome', emoji;
      left: 0;
      color: $text-secondary;
    }
  }
}
