.patient_quest {
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: $radius;
  .acceptance {
    background: #f6f6f6;
    padding: 1rem;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    h5 {
      color: $text-secondary;
      margin-bottom: 0.25rem;
    }
    p {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .help_center {
    background: #f6f6f6;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    .item {
      position: relative;
      padding-bottom: 0.75rem;
      h6 {
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        color: $text-primary;
        & + p {
          margin-bottom: 0;
        }
      }
    }
  }
  .dataForm {
    background: #fff;
    padding: 1rem;
     
  }
}