.podcast_wrapper {
  @extend .wrapContainer;
  min-height: 500px;
  position: relative;
  .banner {
    position: relative;
    background: #043263;
    .container {
      @extend .innerContainer;
      img {
        max-width: auto;
      }
    }
  }
  .sectionContainer {
    @extend .innerContainer;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 2rem 0;
    position: relative;
    z-index: 1000;
    .browse_episodes {
      color: $text-secondary;
      margin-bottom: 1rem;
      h5 {
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        & + p {
          margin-bottom: 0;
          color: $text-body;
          font-style: italic;
        }
      }
    }
    .episodes_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .episodes {
        display: grid;
        grid-template-columns: 200px auto;
        column-gap: 1.25rem;
        align-items: center;
        border: $border;
        border-radius: $radius;
        .episodes_thumbnail {
          position: relative;
          border-top-left-radius: $radius;
          border-bottom-left-radius: $radius;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
          }
        }
        .episodes_info {
          padding-right: 1rem;
          a {
            display: inline-block;
            background: $bg-secondary;
            color: #fff;
            border-radius: 2rem;
            padding: 2px 0.75rem;
            padding-left: 1.75rem;
            position: relative;
            text-decoration: none;
            img {
              position: absolute;
              left: 2px;
              top: 50%;
              transform: translateY(-50%);
              width: 22px;
              height: 22px;
            }
          }
        }
        .small_podcast_player {
          display: none;
        }
      }
    }
  }
  @include media-max(767px) {
    min-height: auto;
    padding: 1rem;
    .sectionContainer {
      padding: 0rem 0;
      .browse_episodes {
        margin-bottom: 0;
      }
      .episodes_wrap {
        row-gap: 1rem;
        .episodes {
          display: grid;
          grid-template-columns: 150px auto;
          .episodes_thumbnail img {
            border-bottom-left-radius: 0;
          }
          .episodes_info .podcast_player {
            display: none;
          }
          .small_podcast_player {
            display: block;
            grid-column: 1 / 3;
            padding: 0.75rem;
          }
        }
      }
    }
  }
}

// CUSTOME STYLE FOR AUDIO PLAYER

#podcastPlayer,
#devicepodcastPlayer {
  position: relative;
  div.rhap_container {
    box-shadow: none;
    padding: 0;
    .rhap_main {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      .rhap_controls-section {
        flex: none;
        margin: 0;
      }
    }
  }
}
