@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@import "layout";
@import "component";
@import "template";

.wrapper {
  @extend .wrapContainer;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.templateBody {
  @extend .wrapContainer;
  .sourceTemplate {
    display: flex;
    flex-direction: column;
    .sectionWrap {
      @extend .wrapContainer;
      padding: 1rem 0;
      .sectionContainer {
        @extend .innerContainer;
      }
    }
    .showError {
      text-align: center;
      padding: 2rem 0;
      span {
        display: inline-block;
        color: $text-error;
        padding: 0.25rem 0.75rem;
        text-transform: capitalize;
      }
    }
    .showLoading {
      text-align: center;
      padding: 2rem 0;
      img {
        width: 250px;
      }
    }
  }
}

:root {
  /* COLORS */
  --cookie-consent-banner-colors-primary: #81c784;
  --cookie-consent-banner-colors-primary-border: #81c784;
  --cookie-consent-banner-colors-primary-content: #fff;
  --cookie-consent-banner-colors-secondary: transparent;
  --cookie-consent-banner-colors-secondary-border: #fff;
  --cookie-consent-banner-colors-secondary-content: #fff;
  --cookie-consent-banner-colors-background-body: rgba(25, 31, 34, 0.92);
  --cookie-consent-banner-colors-text: #fff;

  /* BORDER-RADIUS */
  --cookie-consent-banner-border-radius-buttons: 3rem;
  --cookie-consent-banner-border-radius-body: 0;

  /* SPACINGS */
  --cookie-consent-banner-spacings-container-padding-top: 0rem;
  --cookie-consent-banner-spacings-container-padding-left: 0rem;
  --cookie-consent-banner-spacings-container-padding-bottom: 0rem;
  --cookie-consent-banner-spacings-container-padding-right: 0rem;

  --cookie-consent-banner-spacings-body-padding-top: 0.25rem;
  --cookie-consent-banner-spacings-body-padding-left: 2rem;
  --cookie-consent-banner-spacings-body-padding-bottom: 1rem;
  --cookie-consent-banner-spacings-body-padding-right: 2rem;

  /* Z-INDEX */
  --cookie-consent-banner-z-index-container: 2000;

  /* FONTS */
  --cookie-consent-banner-font-family-headline: inherit;
  --cookie-consent-banner-font-size-headline: 1.5rem;
  --cookie-consent-banner-font-family-body: inherit;
  --cookie-consent-banner-font-size-body: 0.875rem;
}
