.CookieConsent {
  position: relative;
  a {
    color: #81c784;
    text-decoration: underline;
  }
  .cc_buttons {
    padding-bottom: 0!important;
    button {
      font-size: 0.75rem;
    }
  }


  .cc_body {
    .cc_body {
      form {
        p.cc_text {
          padding-bottom: 0.25rem!important;
        }
      }
    }
  }
}