.navigationWrap {
  @extend .wrapContainer;
  background: $bg-primary;
  color: #fff;
  .navigation {
    @extend .innerContainer;
    display: flex;
    column-gap: 1.25rem;
    align-items: center;
    justify-content: center;
    ul {
      display: flex;
      flex-wrap: nowrap;
      list-style-type: none;
      margin: 0;
      li {
        position: relative;
        a {
          display: block;
          color: #fff;
          padding: 1.25rem 0.75rem;
          text-decoration: none;
          & + ul {
            width: 170px;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 2000;
            background: $bg-secondary;
            flex-direction: column;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            display: none;
            li {
              position: relative;
              &:last-of-type {
                border-bottom-left-radius: $radius;
                border-bottom-right-radius: $radius;
                a {
                  border-bottom-left-radius: $radius;
                  border-bottom-right-radius: $radius;
                }
              }
              a {
                padding: 0.375rem 0.75rem;
                background: $bg-secondary;
                &:hover {
                  background: darken($bg-secondary, $amount: 5%);
                }
              }
            }
          }
          &.active,
          &:hover {
            background: $bg-secondary;
            color: #fff;
          }
        }
        &:hover {
          background: $bg-secondary;
          ul {
            display: flex;
          }
        }
      }
    }
    span.register a {
      display: inline-block;
      background: $bg-secondary;
      padding: 0.25rem 0.75rem;
      color: #fff;
      border-radius: 2rem;
      font-size: 0.75rem;
      i.fa {
        margin-right: 0.25rem;
      }
    }
  }
  @include media-max(767px) {
    position: relative;
    background: none;
    .navigation {
      display: flex;
      flex-direction: column;
      ul {
        display: none;
      }
    }
  }
}
