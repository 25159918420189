.searchTemplate {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  .dataForm .data_row {
    display: grid;
    grid-template-columns: max-content auto 100px;
    align-items: center;
    column-gap: 0rem;
    margin-bottom: 2rem;
    .label {
      background: #f1f1f1;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      border: $border;
      border-right: none;
      padding: 0.5rem 0.75rem;
      i.fa {
        color: $text-secondary;
        margin-right: 0.25rem;
      }
    }
    .inputField {
      margin-bottom: 0;
      input[type="text"] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.button {
        padding-left: 1rem;
      }
    }
  }
  .searchResult {
    display: flex;
    flex-direction: column;
    .loadingContainer {
      text-align: center;
      img {
        width: 200px;
      }
    }
    .error {
      color: $text-error;
      font-size: 0.75rem;
    }
    .fetchData {
      display: flex;
      flex-direction: column;
      .countRecord {
        font-weight: 500;
        color: $text-primary;
        margin-bottom: 0.75rem;
        font-size: 0.75rem;
      }
      .fetchRows {
        display: block;
        border-radius: $radius;
        border: $border;
        padding: 0.5rem 0.75rem;
        background: #fff;
        margin-bottom: 0.75rem;
        &:nth-of-type(odd) {
          background: #f1f1f1;
        }
        h6 {
          color: $text-secondary;
          margin-bottom: 0.25rem;
          font-weight: 500;
          cursor: pointer;
          & + p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
