.newsletterSubscription {
  @extend .wrapContainer;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  .pageTitle {
    position: relative;
    margin-bottom: 1rem;
    text-align: center;
    h4 {
      margin-bottom: 0;
      small {
        display: block;
        font-size: 0.75rem;
        font-style: italic;
      }
    }
  }
  .dataForm {
    padding: 1rem 5rem;
  }
  @include media-max(767px) { 
    padding: 0rem;
    h4 {
      font-size: 1rem;
    }
    .dataForm {
      padding: 0rem;
    }
  }
}