.policiesContent {
  @extend .wrapContainer;
  a {
    color: red;
    text-decoration: underline;
  }
  &.cookie-policy {
    position: relative;
    h6 {
      color: $text-primary;
      margin-bottom: 0.25rem;
    }
    table {
      border: $border;
      border-radius: $radius;
      margin-bottom: 1rem;
      border-spacing: 0;
      tr {
        background: #fff;
        th,
        td {
          padding: 0.25rem 0.75rem;
          border-bottom: $border;
          &:first-of-type {
            border-right: $border;
          }
        }
        th {
          padding: 0.75rem 0.75rem;
        }
        &:nth-of-type(odd) {
          background: #f6f6f6;
        }
        &:first-of-type th {
          background: darken($color: #f6f6f6, $amount: 5%);
          color: $text-primary;
          &:first-of-type {
            border-top-left-radius: $radius;
          }
          &:last-of-type {
            border-top-right-radius: $radius;
          }
        }
        &:last-of-type td {
          border-bottom: none;
          &:first-of-type {
            border-bottom-left-radius: $radius;
          }
          &:last-of-type {
            border-bottom-right-radius: $radius;
          }
        }
      }
    }
  }
  ol, ul {
    list-style-position: outside;
    margin-left: 1rem;
  }
}
