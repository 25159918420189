.registration {
  @extend .wrapContainer;
  display: flex;
  flex-direction: column;
  .alertMessage {
    display: flex;
    flex-direction: column;
  }
  .pageTitle {
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    h4 {
      margin-bottom: 0; 
      small {
        display: block;
        font-size: 0.75rem;
        font-style: italic; 
      } 
    }
  }
  .annualMembership {
    position: relative;
    border-radius: $radius;
    border: $border;
    padding: 1rem;
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      position: relative;
      h6 {
        margin-bottom: 0.25rem;
        & + span {
          display: block;
          padding-bottom: 0.75rem;
        }
      }
    }
    strong {
      font-size: 1.5rem;
      color: $text-green;
    }
  }

  .authorization {
    margin-top: 1rem;
    position: relative;
    border-radius: $radius;
    border: $border;
    padding: 1rem;
    background: #f1f1f1;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    h6 {
      margin-bottom: 0.25rem;
      color: $text-secondary;
      & + p {
        margin-bottom: 0.75rem;
        a {
          color: red;
          text-decoration: underline;
        }
      }
    }
     
    .police_accept {
      font-size: 0.75rem;
      ul {
        list-style-type: none;
        margin: 0;
        li {
          position: relative;
          padding-left: 2rem;
          padding-bottom: 0.5rem;
          .erroralert {
            color: $text-error;
          }
          &:last-of-type {
            padding-bottom: 0;
          }
          img {
            width: 16px;
            position: absolute;
            left: 0;

          }
          input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 5px;
          }
          a {
            color: red;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .instruction {
    position: relative;
    position: relative;
    border-radius: $radius;
    border: $border;
    padding: 1rem;
    background: #fff;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 1rem;
    ul {
      list-style-type: none;
      margin-bottom: 0;
      li {
        padding-left: 0.75rem;
        line-height: 1.75;
        position: relative;
        a {
          color: red;
          text-decoration: underline;
        }
        &:before {
          content: "\f105";
          position: absolute;
          font-family: "FontAwesome";
          left: 0;
          color: $text-secondary;
        }
        strong {
          font-weight: 500;
        }
      }
    }
  }
}