.gridPost {
  @extend .innerContainer;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 1rem;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $bg-gray;
    border-radius: $radius;
    border: $border;
    overflow: hidden;
      .thumbnail {
        position: relative;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        img {
          height: 250px;
          @media (max-width:767px) {
            height:unset;
          }
        }
        &:hover {
          text-decoration: none;
          transition: $transition;
            @include imgTransform;
            @include imgGrayscale;
        }
      }
      .title {
        padding: 0.75rem;
        small {
          color: $text-primary;
          & + p {
            margin-bottom: 0;
            font-weight: 400;
          }
        }
      }
  }
  .norecordFound {
    grid-column: 1 / 8;
  }
  @include media-max(992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-max(767px) {
    display: flex;
    flex-direction: column;
  }
}
