.topics {
  @extend .innerContainer;
  display: grid;
  grid-template-columns: 250px auto;
  align-items: flex-start;
  column-gap: 1rem;
  .category {
    border: $border;
    border-radius: $radius;
    ul {
      list-style-type: none;
      margin-bottom: 0;
      li {
        position: relative;
        a {
          display: block;
          border-bottom: $border;
          padding: 0.5rem 0.75rem;
          text-decoration: none;
          font-weight: 500;
          background: #fff;
          &.active,
          &:hover {
            background: $bg-secondary;
            color: #fff;
          }
        }
        &:last-of-type a {
          border-bottom-left-radius: $radius;
          border-bottom: none;
        }
        &:first-of-type a {
          border-top-left-radius: $radius;
        }
      }
    }
  }
  .pagedata {
    border-radius: $radius;
    border: $border;
    .disclaimer {
      padding: 0.5rem 1rem;
      font-style: italic;
      a {
        color: red;
        text-decoration: underline;
      }
    }
  }
  .customer_review {
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.5rem 1rem;
    background: #fff;
    border-bottom: $border;
    &:first-of-type {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }
    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }
    &:nth-of-type(even) {
      background: #f6f6f6;
    }
    h6 {
      margin-bottom: 0.25rem;
      color: $text-secondary;
      a {
        color: $text-secondary;
      }
      & + p {
        margin-bottom: 0.25rem;
      }
    }
    .source a {
      display: inline-block;
      color: $text-secondary;
      padding: 1px 0.5rem;
      background: #c1c1c1;
      border-radius: 2rem;
      font-size: 0.75rem;
      text-decoration: none;
      &:hover {
        background: $bg-secondary;
        color: #fff;

      }
    }
  }

  @include media-max(767px){
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0;
    .category {
      border-right: $border;
      border-radius: $radius!important;
      width: 100%;
      ul li {
        position: relative;
        &:last-of-type a {
          border-bottom-left-radius: $radius;
          border-bottom-right-radius: $radius;
        }
        &:first-of-type a {
          border-top-left-radius: $radius;
          border-top-right-radius: $radius;
        }
      }
    }
    .FaqListing {
      border-top-left-radius: $radius;
    }
  }
}
