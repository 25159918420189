.contact {
  @extend .innerContainer;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem 1rem 0;
  // PAGEHEADER
  .pageHeader {
    position: relative;
    &:before {
      content: "";
      width: 100px;
      height: 1px;
      background: $bg-secondary;
      position: absolute;
      left: 0rem;
      bottom: 0;
    }
    h4 {
      font-weight: 500;
      margin-bottom: 0;
      text-transform: capitalize;
      font-size: 1.25rem;
      color: $text-body;
    }
  }
  // CONTENT SECTION
  .content_section {
    display: grid;
    grid-template-columns: 650px auto;
    column-gap: 5rem;
    .contact_type {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      a {
        color: $text-secondary;
      }
      .item_box {
        position: relative;
        display: flex;
        flex-direction: column;
        color: $text-secondary;
        small {
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 500;
          color: $text-body;
        }
        ul {
          list-style-type: none;
          font-weight: 400;
          li {
            padding: 2px 0;
            a {
              color: $text-secondary;
            }
          }
        }
      }
    }
  }
  @include media-max(767px) {
    padding: 0rem;
    .content_section {
      display: flex;
      flex-direction: column;
    }
  }
}
