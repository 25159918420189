.footerWrap {
  @extend .wrapContainer;
  padding: 1.75rem 0;
  position: relative;
  background: $bg-primary;
  margin-top: auto;
  .footer {
    @extend .innerContainer;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .explore_links {
      display: grid;
      grid-template-columns: 450px auto;
      align-items: center;
      column-gap: 3rem;
      .supported {
        display: flex;
        column-gap: 2rem;
        justify-content: space-evenly;
      }
      .sitemap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        .nav_item {
          position: relative;
          h5 {
            margin-bottom: 0.75rem;
            color: $text-green-light;
            font-weight: 400;
            & + ul {
              list-style-type: none;
              margin: 0;
              li {
                position: relative;
                padding-bottom: 0.25rem;
                a {
                  color: #fff;
                  text-decoration: none;
                  font-weight: 400;
                  &:hover {
                    padding-left: 0.25rem;
                    color: $text-green-light;
                  }
                }
              }
            }
          }
        }
      }
    }
    .explore_media {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 2rem;
      position: relative;
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 100;
      }
      a {
        display: inline-block;
        background: $bg-green-light;
        padding: 0.25rem 0.75rem;
        color: #fff;
        border-radius: 2rem;
        color: $text-green;
        font-weight: 500;
        text-decoration: none;
        i.fa {
          margin-right: 0.25rem;
        }
        &:hover {
          background: darken($bg-green-light, $amount: 10%);
          color: $text-green;
        }
      }
    }
    .quick_contact {
      display: flex;
      padding: 0.75rem 0;
      justify-content: space-evenly;
      align-items: center;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          color: $text-green-light;
        }
      }
      .social_profile {
        position: relative;
        ul {
          list-style-type: none;
          display: flex;
          column-gap: 1.75rem;
          margin: 0;
          li {
            position: relative;
            a {
              display: inline-block;
              color: #fff;
              font-size: 1.25rem;
              text-decoration: none;
              &:hover {
                color: $text-green-light;
              }
            }
          }
        }
      }
    }
  }
  @include media-max(767px) {
    padding: 1rem 0;
    .footer {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      .explore_links {
        display: flex;
        flex-direction: column;
        .sitemap {
          padding-top: 0.75rem;
          column-gap: 1rem;
          .nav_item {
            position: relative;
            h5 {
              font-size: 0.875rem;
              & + ul {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
      .explore_media {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        row-gap: 0.75rem;
        column-gap: 0.75rem;
        img {
          display: none;
        }
        a {
          &:last-of-type {
            grid-column: 1 / 3;
          }
        }
      }
      .quick_contact {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        .item.social_profile {
          margin-top: 0.5rem;
          ul li a {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
