.explore {
  @extend .wrapContainer;
  display: flex;
  flex-direction: column;
  
  a.highlight {
    text-decoration: underline;
    color: #dd3333;
  }
  .page_header {
    position: relative;
    border-bottom: $border;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    h5 {
      color: $text-secondary;
      margin-bottom: 0.25rem;
      & + strong {
        color: $text-primary;
      }
    }
  }
  .page_body {
    position: relative;
    h6.subtitle {
      color: $text-primary;
      margin-bottom: 0.75rem;
    }
    ul {
      list-style-type: none;
      list-style-position: inside;
      li {
        padding-left: 0.75rem;
        line-height: 1.75;
        position: relative;
        &:before {
          content: "\f105";
          position: absolute;
          font-family: "FontAwesome";
          left: 0;
          color: $text-secondary;
        }
      }
    }
    .graphic {
      width: 100%;
      text-align: center;
      padding: 2rem;
      img {
        width: 100%;
        border-radius: 1rem;
        border: $border;
      }
    }
    .profile {
      display: grid;
      grid-template-columns: max-content auto;
      column-gap: 1rem;
      margin-bottom: 1rem;
      align-items: center;
      .picture img {
        border-radius: 1rem;
        border: $border;
      }
    }
  }
  .FaqListing {
    border-radius: $radius;
    border: $border;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;
    .heading {
      background: #f6f6f6;
      border-bottom: $border;
      padding: 0.75rem;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      h5 {
        margin-bottom: 0;
        font-size: 1.25rem;
        color: $text-primary;
      }
    }
    .faqs {
      position: relative; 
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0.75rem 1rem;
      &:before {
        content: "";
        width: 100px;
        height: 0.5px;
        position: absolute;
        left: 1rem;
        bottom: 0;
        background: $bg-secondary;
      }
      &:last-of-type {
        position: relative;
        &:before {
          display: none;
        }
      }
      h6 {
        color: $text-secondary;
        margin-bottom: 0;
        font-weight: 500; 
      }
      .answer {
        width: 100%;
        position: relative;
        padding-top: 0.25rem;
        p {
          margin-bottom: 1rem;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        a {
          color: red;
          text-decoration: underline;
        }
      }
    }
  }
  .page_footer {
    display: flex;
    flex-direction: column;
    padding: 0.75rem; 
    border: $border;
    border-top: none;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    background: #f6f6f6;
    .switch_faq {
      position: relative;
      a {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        background: $bg-secondary;
        color: #fff;
        border-radius: 2rem;
        font-size: 0.75rem;
        i.fa {
          margin-right: 0.25rem;
        }
      }
    }
    .switch_contact {
      font-style: italic;
      a {
        color: $text-secondary;
        text-decoration: underline;
      }
    }
  }
}
