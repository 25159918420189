.CookieSettings {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background: transparentize($color: #000000, $amount: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .settingContainer {
    width: 90%;
    max-width: 950px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 0.75rem;
    .section {
      padding: 1rem;
      background: $bg-gray;
      position: relative;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      &:before {
        content: "";
        width: 25%;
        height: 0.5px;
        background: $bg-primary;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 100;
      }
      h5 {
        margin-bottom: 0.25rem;
        color: $text-primary;
        font-weight: 500;
        & + p {
          margin-bottom: 0;
          font-size: 0.75rem;
        }
      }
    }
    .hander {
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      a {
        display: block;
        background: $bg-success;
        padding: 0.75rem;
        text-align: center;
        color: #fff;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        &:hover {
          background: darken($bg-success, $amount: 5%);
        }
      }
    }
    .settingtype {
      display: flex;
      flex-direction: column;
      height: 400px;
      overflow-y: scroll;
      .mangeCoockies {
        display: flex;
        flex-direction: column;
        .title {
          background: #f1f1f1;
          border-bottom: $border;
          padding: 0.5rem 0.75rem;
          position: relative;
          color: $text-primary;
          font-weight: 500;
          label {
            position: absolute;
            right: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
            color: $text-body;
          }
        }
        .detail {
          padding: 0.5rem 0.75rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}
