* {
	padding:0;
	margin:0;
}

*, *::after, *::before {
	-webkit-box-sizing:border-box; 
	-moz-box-sizing:border-box; 
	box-sizing:border-box;
}

html {
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust:100%;
	text-size-adjust:100%;

	-webkit-font-smoothing:antialiased;
	-moz-font-smoothing:antialiased;
	font-synthesis:antialiased;
}

@-ms-viewport {
	width: device-width;
}

body {
	padding:0;
	margin:0;
	font-family: $bodyFont;
	font-size: $bodyFontsize;
	font-weight: 400;
	line-height: 1.5;
	color: $text-body;
	text-align: left;
}

html, body {
	min-height: 100%;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section { 
	display:block;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 500;
	line-height: 1.2;
	color: inherit;
}

h1, .h1 {
	font-size: 2.5rem;
}
h2, .h2 {
	font-size: 2rem;
}
h3, .h3 {
	font-size: 1.75rem;
}
h4, .h4 {
	font-size: 1.5rem;
}
h5, .h5 {
	font-size: 1.25rem;
}
h6, .h6 {
	font-size: 1rem;
}

h1 small, h2 small, h3 small,
.h1 small, .h2 small, .h3 small {
	font-weight:normal;
	font-size:75%;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol, ul {
	margin-top: 0;
	margin-bottom: 1rem;
	list-style-position: inside;
}

ol ol, ul ul, ol ul, ul ol {
	margin-bottom: 1rem;
}

blockquote {
	margin: 0 0 1rem;
}

a {
	color: $text-primary;
	text-decoration:none;
	transition: $transition;
	&:hover {
		text-decoration: underline;
		color: darken($text-primary, $amount: 10%);
	}
}

hr {
	height: 1px;
	border:none;
	background: #b7c1c4;
}

img {
	max-width: 100%;
	vertical-align: bottom;
}

small {
	font-size: 85%;
}