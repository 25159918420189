.breadCrumbWrap {
  @extend .wrapContainer;
  background: $bg-gray;
  padding: .75rem 0;
  .breadCrumb {
    @extend .innerContainer;
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      li {
        position: relative;
        text-transform: capitalize;
        &:after {
          content: "\f105";
          font-family: "FontAwesome";
          display: inline-block;
          margin: 0 0.5rem;
        }
        &:last-of-type {
          a {
            pointer-events: none;
            cursor: text;
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  @include media-max(767px) {
    display: none;
  }
}